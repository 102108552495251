import React from 'react';

import { Card } from 'react-bootstrap';
import Button from 'components/base/Button';
import { Dropdown, Form } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import Unicon from 'components/base/Unicon';
import { UilCommentInfo } from '@iconscout/react-unicons';
export const OrderCalendar = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <Card className="p-3 mb-3">
      <div className="calendarHead">
        <div className="subHead">
          <h4>Calendar</h4>
        </div>
        <ul>
          <li>
            <Button variant="primary"> Edit Collection </Button>
          </li>
          <li>
            <Button variant="primary"> Book Return </Button>
          </li>
          <li>
            <Button variant="primary"> Book Access </Button>
          </li>
        </ul>
      </div>
      <div>
        <div className="collectionDetails">
          <h5>
            Collection Details{' '}
            <Badge pill bg="success">
              Completed
            </Badge>
          </h5>
          <Dropdown>
            <Dropdown.Toggle variant="phoenix-primary">Action</Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="mb-2">
          <strong>Collection Address</strong> : Flat 89, Blenheim Mansions, 3
          Mary Neuner Road, London, Greater London, n8 0ez
        </div>
        <div className="row">
          <div className="col-md-6 mb-2">
            <strong>Date</strong> : 28-11-2023
          </div>
          <div className="col-md-6 mb-2">
            <strong>Time</strong> : 08:00-12:00
          </div>
          <div className="col-md-6 mb-2">
            <strong>Depot</strong> : Cadogan Tate (Action)
          </div>
        </div>
        <div className="collection-form">
          <div className="collectionUser">
            <Form.Select aria-label="Default select example">
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </div>
          <div className="vechileType">
            <Form.Control type="text" placeholder="Normal text" />
          </div>
          <div>
            <Button variant="primary"> Primary </Button>
          </div>
        </div>
        <div className="access-details">
          <div className="access-head">
            <h5>
              Access Details{' '}
              <Badge pill bg="success">
                Completed
              </Badge>
            </h5>

            <Dropdown>
              <Dropdown.Toggle variant="phoenix-primary">
                Action
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="row">
            <div className="col-md-6 mb-2">
              {' '}
              <strong>Date</strong> : 10-01-2024
            </div>
            <div className="col-md-6 mb-2">
              <strong>Time</strong> : 11:00
            </div>
            <div className="col-md-12 mb-2">
              <strong>Access Pod Number</strong> : Ct2120
            </div>
            <div className="col-md-12 mb-2">
              <strong>Depot</strong> : Cadogon Tate (Action)
              <Unicon icon={UilCommentInfo} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
