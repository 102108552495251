/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/Table/depotsFilter';
import { makeRequest } from 'helpers/apiCall';

export const getDepotsList = createAsyncThunk(
  'depots/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `depot?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search} &search_franchise=${queryParams.filter.search_franchise}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createDepot = async (depotData: any) => {
  const response = await makeRequest('POST', 'depot', depotData);
  return response;
};
