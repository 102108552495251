import { useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import productIcon from 'assets/img/setting/cubes.png';
import userIcon from 'assets/img/setting/group.png';
import franchiseIcon from 'assets/img/setting/branch.png';
import timeLineIcon from 'assets/img/setting/project.png';
import depotIcon from 'assets/img/setting/stocks.png';
import agentIcon from 'assets/img/setting/call-center.png';
import reportIcon from 'assets/img/setting/pie-chart.png';
import contriesIcon from 'assets/img/setting/world-map.png';
import promotionIcon from 'assets/img/setting/promotion.png';

const NineDotMenu = () => {
  const [items] = useState([
    {
      img: productIcon,
      title: 'Products',
      to: 'settings/product',
      icon: 'users'
    },
    { img: userIcon, title: 'Users', to: 'settings/users', icon: 'users' },
    {
      img: franchiseIcon,
      title: 'Franchises',
      to: 'settings/franchises',
      icon: 'users'
    },
    {
      img: timeLineIcon,
      title: 'Timeline',
      to: 'settings/timeline',
      icon: 'users'
    },
    {
      img: promotionIcon,
      title: 'Promotions',
      to: 'settings/promotion',
      icon: 'users'
    },
    { img: depotIcon, title: 'Depots', to: 'settings/depots', icon: 'users' },
    {
      img: contriesIcon,
      title: 'Country',
      to: 'settings/country',
      icon: 'users'
    },
    {
      img: agentIcon,
      title: 'Sale Agent Sale Availability',
      to: 'settings/availability',
      width: '20',
      icon: 'users'
    },
    {
      img: reportIcon,
      title: 'Reports',
      to: 'settings/Reports',
      icon: 'users'
    }
  ]);
  return (
    <Dropdown.Menu
      align="end"
      className="navbar-dropdown-caret py-0 dropdown-nine-dots shadow border border-300"
    >
      <Card
        className="position-relative border-0"
        style={{ height: '20rem', minWidth: 244 }}
      >
        <Scrollbar>
          <Card.Body className="pt-3 px-3 pb-0">
            <Row className="text-center align-items-center g-0">
              {items.map(item => (
                <Col xs={4} key={item.title}>
                  <Link
                    to={item.to}
                    className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3"
                  >
                    <img src={item.img} alt="setting" width={40} />
                    <p className="mb-0 text-black text-truncate fs-10 mt-1 pt-1">
                      {item.title}
                    </p>
                  </Link>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Scrollbar>
      </Card>
    </Dropdown.Menu>
  );
};

export default NineDotMenu;
