import { Navbar } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import NavbarBrand from 'components/navbars/nav-items/NavbarBrand';
import NavItems from 'components/navbars/nav-items/NavItems';
import NavItemsSlim from 'components/navbars/nav-items/NavItemsSlim';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import { useBreakpoints } from 'providers/BreakpointsProvider';
// import { Client } from '@elastic/elasticsearch';
// import { useState } from 'react';

// const client = new Client({ node: 'http://13.41.179.37:9200' });
const NavbarTopDefault = () => {
  const {
    config: { navbarTopShape, navbarTopAppearance }
  } = useAppContext();

  const { breakpoints } = useBreakpoints();
  // const [searchQuery, setSearchQuery] = useState('');
  // const [searchResults, setSearchResults] = useState([]);

  // const handleSearch = async () => {
  //   try {
  //     const check = await client.search({
  //       index: 'users', // Replace with your Elasticsearch index
  //       body: {
  //         query: {
  //           match: { name: searchQuery } // Replace with the field you want to search
  //         }
  //       }
  //     });
  //     console.log('check', check);

  //     // setSearchResults(body.hits.hits);
  //   } catch (error) {
  //     console.error('Error searching:', error);
  //   }
  // };
  return (
    <Navbar
      className={classNames('navbar-top fixed-top', {
        'navbar-slim': navbarTopShape === 'slim',
        'navbar-darker': navbarTopAppearance === 'darker'
      })}
      expand
      variant=""
    >
      <div className="navbar-collapse justify-content-between">
        <NavbarBrand />

        {navbarTopShape === 'default' ? (
          <>
            {breakpoints.up('lg') && (
              <>
                <DropdownSearchBox
                  className="navbar-top-search-box"
                  inputClassName="rounded-pill"
                  size="sm"
                  style={{ width: '45rem' }}
                >
                  <SearchResult />
                </DropdownSearchBox>
                {/* <div>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  <button onClick={handleSearch}>Search</button>

                  <ul>
                    {searchResults.map((result: any) => (
                      <li key={result._id}>{result._source.your_field_name}</li>
                    ))}
                  </ul>
                </div> */}
              </>
            )}
            <NavItems />
          </>
        ) : (
          <NavItemsSlim />
        )}
      </div>
    </Navbar>
  );
};

export default NavbarTopDefault;
