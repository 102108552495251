/* eslint-disable @typescript-eslint/no-explicit-any */
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import {
  feedsCreate,
  feedsUpdate,
  getFeedsList,
  getTimelineList
} from '../../../../redux/features/Settings/Timeline/timelineApi';
import { useSelector } from 'react-redux';
import { Accordion, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { enqueueSnackbar } from 'notistack';

const TimelineList = () => {
  // List of options for the dropdown
  const options = [
    'order',
    'operation',
    'billing',
    'email',
    'contact',
    'deal',
    'task',
    'note',
    'call'
  ];
  const dispatch = useAppDispatch();
  const profileList = useSelector(
    (state: RootState) => state.timeline.profileList.data
  );
  const feedsList = useSelector(
    (state: RootState) => state.timeline.feedsList.data
  );
  const permission = useSelector(
    (state: RootState) => state.timeline.permission
  );
  const location = useLocation();

  // Handle check event for feeds
  const handleFeedCheck = async (e: any, id: any, name: any) => {
    const data = {
      role_id: id,
      name: name
    };
    // If checkbox is checked, create a feed
    if (e.target.checked) {
      try {
        const response = await feedsCreate(data);
        if (response) {
          dispatch(getFeedsList());
        }
      } catch (error: any) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        console.error('error creating timeline', error.response.data.message);
      }
    } else {
      // If checkbox is not checked, update the feed
      const updateReq = {
        id: id,
        data: data
      };
      try {
        const response = await feedsUpdate(updateReq);
        if (response) {
          dispatch(getFeedsList());
        }
      } catch (error: any) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        console.error('error updating timeline', error.response.data.message);
      }
    }
  };

  const handleTimelineList = async (): Promise<void> => {
    dispatch(getTimelineList());
  };

  useEffect(() => {
    handleTimelineList();
  }, [location]);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Timeline</h2>
        {permission?.setting_feed?.view && (
          <Accordion className="">
            {profileList?.map((profile: any, index: number) => (
              <Accordion.Item
                className={classNames({
                  'border-top border-300': index === 0
                })}
                eventKey={String(index)}
                key={profile.id}
              >
                <Accordion.Header>{profile.name}</Accordion.Header>
                <Accordion.Body className="pt-0">
                  {options?.map((option: any, i: number) => (
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      key={i}
                      label={option}
                      // defaultChecked={list.status}
                      checked={
                        feedsList &&
                        feedsList.length &&
                        feedsList.find(
                          (element: any) =>
                            element.role_id === profile.id &&
                            element.name === option
                        )
                      }
                      onChange={(e: any) =>
                        handleFeedCheck(e, profile.id, option)
                      }
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default TimelineList;
