/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterMenu } from 'components/common/FilterButtonGroup';

export const filterMenus = (franchiseList: any, agentList: any) => {
  const franchiseItems = franchiseList.data.map((franchise: any) => ({
    label: franchise.name,
    id: franchise.id.toString()
  }));
  const agentItems = agentList.data.map((agent: any) => ({
    label: agent.first_name,
    id: agent.id.toString()
  }));

  const filterMenus: FilterMenu[] = [
    {
      label: 'Orders',
      id: 'search_view',
      items: [
        { id: 'all_orders', label: 'All Orders' },
        { id: 'my_orders', label: 'My Orders' },
        { id: 'new_last_week', label: 'New Last Week' },
        { id: 'new_this_week', label: 'New This Week' }
      ]
    },
    {
      label: 'Franchise',
      id: 'search_franchise',
      items: franchiseItems
    },
    {
      label: 'Agents',
      id: 'search_agent',
      items: agentItems
    },
    {
      label: 'Status',
      id: 'search_status',
      items: [
        { id: '0', label: 'Collection' },
        { id: 1, label: 'Access' },
        { id: 2, label: 'Return' },
        { id: 3, label: 'Cancelled' }
      ]
    }
  ];

  return filterMenus;
};
