import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getContactsList } from './contactsApi';

interface ContactsState {
  list: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
}

const initialState: ContactsState = {
  list: {
    isLoading: false,
    data: {
      data: [],
      meta: {}
    },
    isError: false
  }
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getContactsList.pending, state => {
      state.list.isLoading = true;
      state.list.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getContactsList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
        state.list.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getContactsList.rejected, state => {
      state.list.isLoading = false;
      state.list.isError = true;
    });
  }
});

export default contactsSlice.reducer;
