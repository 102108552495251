import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Customers, {
  customersTableColumns
} from '../../../../components/tables/CustomersTable';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getCustomersList } from '../../../../redux/features/Settings/Customers/customersApi';

const CustomersList = () => {
  const dispatch = useAppDispatch();
  const customers = useSelector(
    (state: RootState) => state.customers.list.data
  );
  const isLoading = useSelector(
    (state: RootState) => state.customers.list.isLoading
  );
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState({
    list: [],
    meta: { total: null, current_page: 1 }
  });

  const table = useAdvanceTable({
    data: customers?.data || [],
    columns: customersTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(customers?.meta?.total),
      current_page: Number(customers?.meta?.current_page),
      isLoading: isLoading
    };
  }, [location, customers?.meta, isLoading]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  const handleCustomersList = async (): Promise<void> => {
    dispatch(
      getCustomersList({
        pageNumber: paginationProps?.page,
        pageSize: paginationProps?.dataPerPage,
        sortField: '',
        sortOrder: '',
        filter: {
          search: '',
          search_view: '',
          search_agent: '',
          search_franchise: ''
        }
      })
    );
  };
  useEffect(() => {
    handleCustomersList();
  }, [location]);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Customers</h2>
        {/* <FilterTab tabItems={tabItems} className="mb-2" /> */}

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search"
                onChange={handleSearchInputChange}
              />
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <Customers paginationProps={paginationProps} />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default CustomersList;
