import { makeRequest } from 'helpers/apiCall';

export const LOGIN_URL_LIVE = 'login';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function login(email: string, password: string): Promise<any> {
  return makeRequest('POST', LOGIN_URL_LIVE, {
    email,
    password
  });
}

export function forgetPassword(email: string): Promise<any> {
  return makeRequest('POST', 'forgot-password', {
    email
  });
}
