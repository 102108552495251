import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getProductsList } from '../../../../redux/features/Settings/Products/productsApi';
import ProductsTable, {
  productTableColumns
} from 'components/tables/ProductTable';

const filterMenus: FilterMenu[] = [
  {
    label: 'Depot status',
    items: [
      {
        label: 'All'
      },
      {
        label: 'Depot Active'
      },
      {
        label: 'Depot InActive'
      }
    ]
  },
  {
    label: 'Franchise',
    items: [
      {
        label: 'Action'
      },
      {
        label: 'Another Action'
      },
      {
        label: 'Something else here'
      }
    ]
  }
];

const ProductsList = () => {
  const dispatch = useAppDispatch();
  const depots = useSelector((state: RootState) => state.products.list.data);

  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState({
    list: [],
    meta: { total: null, current_page: 1 }
  });

  const table = useAdvanceTable({
    data: depots?.data || [],
    columns: productTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(depots?.total),
      current_page: Number(depots?.nextoffset),
      offset: parseInt(params.get('page') || '1', 10) - 1
    };
  }, [location, depots?.nextoffset]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  const handleProductList = async (): Promise<void> => {
    console.log('paginationProps', paginationProps);

    dispatch(
      getProductsList({
        pageNumber: paginationProps?.page,
        pageSize: paginationProps?.dataPerPage,
        offset: Number(paginationProps?.offset),
        sortField: '',
        sortOrder: '',
        filter: { search: '', search_franchise: '' }
      })
    );
  };
  useEffect(() => {
    handleProductList();
  }, [location]);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Products</h2>
        {/* <FilterTab tabItems={tabItems} className="mb-2" /> */}

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search depots"
                onChange={handleSearchInputChange}
              />
              <div className="scrollbar overflow-hidden-y">
                <FilterButtonGroup menus={filterMenus} />
              </div>
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  New Product
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <ProductsTable paginationProps={paginationProps} />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default ProductsList;
