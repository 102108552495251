/* eslint-disable @typescript-eslint/no-explicit-any */
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup from 'components/common/FilterButtonGroup';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useMemo, useState } from 'react';
// import { getCountryList } from './service';
import CountryTable, {
  countryTableColumns
} from 'components/tables/CountryTable';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { getUsersList } from '../../../../redux/features/Settings/Users/usersApi';
import { useSelector } from 'react-redux';
import { getFranchiseList } from '../../../../redux/features/Common/commonApi';
import { filterMenus } from '../components/TableFilters/FilterMenus';

const UsersList = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const users = useSelector((state: RootState) => state.users.list.data);
  const isLoading = useSelector(
    (state: RootState) => state.users.list.isLoading
  );
  const franchiseList = useSelector(
    (state: RootState) => state.common.franchiselist.data
  );
  const [filter, setFilter] = useState({
    search: '',
    search_franchise: '',
    status: ''
  });

  const filterMenu = filterMenus(franchiseList);

  const table = useAdvanceTable({
    data: users.data,
    columns: countryTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(users?.meta?.total),
      current_page: Number(users?.meta?.current_page),
      isLoading: isLoading
    };
  }, [location, users.meta, isLoading]);

  const handleCountryList = async (): Promise<void> => {
    dispatch(
      getUsersList({
        pageNumber: paginationProps?.page,
        pageSize: paginationProps?.dataPerPage,
        sortField: '',
        sortOrder: '',
        filter: filter
      })
    );
  };
  useEffect(() => {
    handleCountryList();
  }, [location, filter]);
  useEffect(() => {
    dispatch(getFranchiseList());
  }, [dispatch]);

  const handleFilter = (key: any, value: any) => {
    setFilter({ ...filter, [key]: value });
  };

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Users</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search orders"
                onChange={e => handleFilter('search', e.target.value)}
              />
              <div className="scrollbar overflow-hidden-y">
                <FilterButtonGroup
                  menus={filterMenu}
                  filter={filter}
                  handleFilter={handleFilter}
                />
              </div>
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add order
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <CountryTable paginationProps={paginationProps} />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default UsersList;
