/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterMenu } from 'components/common/FilterButtonGroup';

export const filterMenus = (franchiseList: any) => {
  const franchiseItems = franchiseList.data.map((franchise: any) => ({
    label: franchise.name,
    id: franchise.id.toString()
  }));

  const filterMenus: FilterMenu[] = [
    {
      label: 'Users',
      id: 'status',
      items: [
        {
          label: 'All Users',
          id: ''
        },
        {
          label: 'Active',
          id: '1'
        },
        {
          label: 'Inactive',
          id: '0'
        }
      ]
    },
    {
      label: 'Fulfilment status',
      id: 'search_franchise',
      items: franchiseItems
    }
  ];

  return filterMenus;
};
