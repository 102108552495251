import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { forgetPassword } from 'pages/Auth/service';
import { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
interface InitialValues {
  email: string;
}
const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  const [isLoading, SetIsLoading] = useState(false);
  const history = useNavigate();
  const initialValues: InitialValues = {
    email: ''
  };
  const handleSubmit = async (values: { email: string }): Promise<void> => {
    SetIsLoading(true);
    try {
      const res = await forgetPassword(values.email);
      if (res.data) {
        enqueueSnackbar(
          "We've emailed you a link to your email address to reset your password",
          { variant: 'success' }
        );
        history('/auth/login');
      }
      SetIsLoading(false);
    } catch (error: any) {
      /* empty */

      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      SetIsLoading(false);
    }
  };
  const validateForm = (values: any) => {
    const errors: any = {};

    // Validation logic for email
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: initialValues,
    validate: validateForm,
    onSubmit: handleSubmit
  });
  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <h4 className="text-1000">Forgot your password?</h4>
        <p className="text-700 mb-5">
          Enter your email below and we will send <br className="d-sm-none" />
          you a reset link
        </p>
        <Form
          className="d-flex align-items-center mb-5"
          onSubmit={formik.handleSubmit}
        >
          <Form.Control
            type="email"
            id="email"
            className="flex-1"
            placeholder="Email"
            {...formik.getFieldProps('email')}
            isInvalid={
              formik.touched.email && formik.errors.email ? true : false
            }
          />
          <Button
            variant="primary"
            className="ms-2"
            type="submit"
            endIcon={<FontAwesomeIcon icon={faChevronRight} className="ms-2" />}
          >
            {isLoading ? (
              <Spinner animation="border" variant="primary" size="sm" />
            ) : (
              'Send'
            )}
          </Button>
        </Form>
        {/* <Link to="#!" className="fs-9 fw-bold">
          Still having problems?
        </Link> */}
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
