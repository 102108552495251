import React from 'react';
import Unicon from 'components/base/Unicon';
import { Card } from 'react-bootstrap';
import {
  UilPen,
  UilMultiply,
  UilClipboardNotes
} from '@iconscout/react-unicons';
export const OrderCustomer = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <Card className="p-3 mb-3">
      {/* Customer  */}
      <div className="customer">
        <div className="subHead mb-2">
          <h5>Customer</h5>
          <span>
            <Unicon icon={UilMultiply} />
          </span>
        </div>
        <div>
          <span className="d-block mb-1">
            <a href="">Luc Carpinelli</a>
          </span>
          <span>1 order</span>
        </div>
      </div>
      {/* Customer  */}
      {/* Contact Information  */}
      <div className="customer">
        <div className="subHead mb-2">
          <h5>Contact Information</h5>
          <span>
            <Unicon icon={UilPen} />
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            <a href="">luc.carpinelli@gmail.com</a>
          </p>
          <span>
            <Unicon icon={UilClipboardNotes} />
          </span>
        </div>
        <div>No phone number</div>
      </div>
      {/* Contact Information  */}
      {/* Shipping Information  */}
      <div className="customer">
        <div className="subHead mb-2">
          <h5>Shipping address</h5>
          <span>
            <Unicon icon={UilPen} />
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-0">
            Luc Carpinelli
            <br />
            68 Plender street
            <br />
            Flat 13
            <br />
            London
            <br />
            NW 10 LB
            <br />
            United Kingdom
            <br />
            <a href="">View map</a>
          </p>
          <span>
            <Unicon icon={UilClipboardNotes} />
          </span>
        </div>
      </div>
      {/* Shipping Information  */}
      {/* Billing Information  */}
      <div className="customer">
        <div className="subHead mb-2">
          <h5>Billing address</h5>
        </div>

        <div>Same as shipping address</div>
      </div>
      {/* Billing Information  */}
    </Card>
  );
};
