/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import DatePicker from 'components/base/DatePicker';
import ReactSelect from 'components/base/ReactSelect';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import moment from 'moment';

const ReportCard = ({
  className,
  title,
  formik,
  from,
  to,
  franchise_id,
  franchiseList,
  handleSubmit
}: {
  className?: string;
  title: string;
  formik?: any;
  from?: string;
  to?: string;
  franchise_id?: string;
  franchiseList?: any;
  handleSubmit?: (arg?: any) => void;
}) => {
  const getOptionLabel = (option: any) => `${option.value} - ${option.label}`;

  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex align-items-center mb-1">
          <h3>{title}</h3>
        </div>

        <Container>
          <Row>
            <Col sm className="p-2 ">
              <Form.Label>Franchise</Form.Label>
              <ReactSelect
                options={
                  franchiseList?.data.map((e: any) => ({
                    value: e.id,
                    label: e.name
                  })) || []
                }
                getOptionLabel={getOptionLabel}
                placeholder="Select organizer..."
                onChange={(e: any) =>
                  formik.setFieldValue(franchise_id, e.value)
                }
              />
            </Col>
            <Col sm className="p-2 ">
              <Form.Label>From</Form.Label>
              <DatePicker
                placeholder="Select Date"
                value={Date.parse(formik?.values[from!])}
                onChange={([date]) => {
                  formik.setFieldValue(from, moment(date).format('yyyy-MM-DD'));
                }}
              />
            </Col>
            <Col sm className="p-2 ">
              <Form.Label>To</Form.Label>
              <DatePicker
                placeholder="Select Date"
                value={Date.parse(formik?.values[to!])}
                onChange={([date]) => {
                  formik.setFieldValue(to, moment(date).format('yyyy-MM-DD'));
                }}
              />
            </Col>
            <Col sm className="p-2 ">
              <button
                className="btn btn-primary  mt-4"
                onClick={() => handleSubmit && handleSubmit(from)}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default ReportCard;
