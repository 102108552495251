import UserCard from 'components/cards/UserCard';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getUsersDetail } from '../../../../redux/features/Settings/Users/usersApi';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';

const UsersDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const user = useSelector((state: RootState) => ({
    user: state.users.detail.data
    // permission: state.auth.permission
  }));

  useEffect(() => {
    if (id) {
      dispatch(getUsersDetail(id));
    }
  }, [id]);

  return (
    <div>
      <UserCard className="mb-3" user={user?.user.data} />
    </div>
  );
};

export default UsersDetail;
