import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { login } from 'pages/Auth/service';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
interface InitialValues {
  email: string;
  password: string;
}
const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const [isLoading, SetIsLoading] = useState(false);
  const history = useNavigate();
  const initialValues: InitialValues = {
    email: '',
    password: ''
  };
  const handleSubmit = async (values: {
    email: string;
    password: string;
  }): Promise<void> => {
    SetIsLoading(true);
    try {
      const res = await login(values.email, values.password);
      if (res.data) {
        const COOKIE_NAME = 'token';
        const EXPIRATION_TIME_IN_HOURS = 48;
        const expirationDate: Date = new Date();
        expirationDate.setTime(
          expirationDate.getTime() + EXPIRATION_TIME_IN_HOURS * 60 * 60 * 1000
        );
        const data = JSON.stringify(res.data.data);
        localStorage.setItem('auth', data);
        Cookies.set(COOKIE_NAME, res.data.data.token, {
          expires: expirationDate
        });
      }
      SetIsLoading(false);
      enqueueSnackbar(res.data.message, { variant: 'success' });
      history('/dashboard/orders');
    } catch (error: any) {
      /* empty */

      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      SetIsLoading(false);
    }
  };
  const validateForm = (values: any) => {
    const errors: any = {};

    // Validation logic for email
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }

    // Validation logic for password
    if (!values.password) {
      errors.password = 'Password is required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validate: validateForm,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    const token: string | undefined = Cookies.get('token');
    if (token) {
      history('/dashboard/orders');
    }
  }, []);

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Sign In</h3>
        <p className="text-700">Get access to your account</p>
      </div>

      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="email"
              type={formik.values.email.includes('@') ? 'email' : 'text'}
              className="form-icon-input"
              placeholder="name@example.com"
              {...formik.getFieldProps('email')}
              isInvalid={
                formik.touched.email && formik.errors.email ? true : false
              }
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-900 fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">Password</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="password"
              type="password"
              className="form-icon-input"
              placeholder="Password"
              {...formik.getFieldProps('password')}
              isInvalid={
                formik.touched.password && formik.errors.password ? true : false
              }
            />
            <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
          </div>
        </Form.Group>
        <Row className="flex-between-center mb-7">
          <Col xs="auto">
            <Form.Check type="checkbox" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                defaultChecked
              />
              <Form.Check.Label htmlFor="remember-me" className="mb-0">
                Remember me
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto">
            <Link
              to={`/pages/authentication/${layout}/forgot-password`}
              className="fs-9 fw-semi-bold"
            >
              Forgot Password?
            </Link>
          </Col>
        </Row>
        <Button variant="primary" className="w-100 mb-3" type="submit">
          {isLoading ? (
            <Spinner animation="border" variant="primary" size="sm" />
          ) : (
            'Sign In'
          )}
        </Button>
      </Form>
      {/* <div className="text-center">
        <Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          Create an account
        </Link>
      </div> */}
    </>
  );
};

export default SignInForm;
