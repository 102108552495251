/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getActiveCountry } from '../../../../../redux/features/Common/commonApi';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { useFormik } from 'formik';
import { DepotForm } from '../Form/DepotForm';
import { createDepot } from '../../../../../redux/features/Settings/Depots/depotsApi';
import { enqueueSnackbar } from 'notistack';

interface DepotModalProps {
  modalShow: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
}

export const DepotModal: React.FC<DepotModalProps> = ({
  modalShow,
  setModalShow,
  count
}) => {
  const dispatch = useAppDispatch();
  const countryList = useSelector(
    (state: RootState) => state.common.countrylist.data
  );
  const initialValues = {
    status: 0,
    name: '',
    short_name: '',
    depot_reference_id: count ? count + 1 : '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    postcode: '',
    country: '',
    latitude: '',
    longitude: '',
    manager_name: '',
    manager_email: '',
    additional_emails: '',
    phone: '',
    depot_hours: [
      { day: '1', open_time: '', close_time: '' },
      { day: '2', open_time: '', close_time: '' },
      { day: '3', open_time: '', close_time: '' },
      { day: '4', open_time: '', close_time: '' },
      { day: '5', open_time: '', close_time: '' },
      { day: '6', open_time: '', close_time: '' },
      { day: '7', open_time: '', close_time: '' }
    ]
  };

  const handleSubmit = async (values: any) => {
    try {
      const response = await createDepot(values);
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      console.error('Error creating depot:', error.response.data.message);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });
  useEffect(() => {
    dispatch(getActiveCountry());
  }, [dispatch]);

  return (
    <div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Depot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DepotForm countryList={countryList} formik={formik} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
          <Button onClick={formik.submitForm}>Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
