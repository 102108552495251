import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getOrdersStats } from '../../../../redux/features/Orders/ordersApi';
import { RootState, useAppDispatch } from '../../../../redux/store';

const OrdersPanel = () => {
  const [selectedValue, setSelectedValue] = useState('Today');

  const handleSelect = (value: any) => {
    setSelectedValue(value);
    let startDate = new Date();
    const endDate = new Date();
    if (value == '7 days') {
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 7);
    } else if (value == '30 days') {
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 30);
    }
    const request = {
      start_date: startDate,
      end_date: endDate
    };
    console.log('request', request);
  };
  const dispatch = useAppDispatch();
  const orderStats = useSelector((state: RootState) => state.orders.stats.data);
  useEffect(() => {
    dispatch(getOrdersStats());
  }, [location]);
  return (
    <div>
      {/* shopify-layout */}
      <div className="panel-layout-section">
        <div className="panel-shadowBevel">
          <div className="panel-box">
            <div className="content-wrapper">
              <div style={{ minWidth: '11rem' }}>
                <Dropdown
                  as={ButtonGroup}
                  className="position-static"
                  onSelect={handleSelect}
                >
                  <Dropdown.Toggle
                    variant="phoenix-secondary"
                    style={{ padding: '30px 0px' }}
                    className="px-7 flex-shrink-0 dropdown-caret-none"
                  >
                    {selectedValue}
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="ms-2"
                      transform="down-2"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      eventKey="Today"
                      active={selectedValue == 'Today'}
                    >
                      Today
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="7 days"
                      active={selectedValue == '7 days'}
                    >
                      Last 7 days
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="30 days"
                      active={selectedValue == '30 days'}
                    >
                      Last 30 days
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="panel-box"
                style={{ width: '100%', position: 'relative', zIndex: 1 }}
              >
                <div className="horizontal-navigation">
                  <div className="container-tab" tabIndex={0}>
                    <div className="panel-inline-stack">
                      <div className="container-info enable-link">
                        <div className="hover-container">
                          <div className="layout-button">
                            <div className="layout-easy compact">
                              <div className="header-easy">
                                <div className="title-easy">
                                  <div className="title-header-easy">
                                    <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                      Total orders
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-content">
                                <div className="content-flex-wrapper">
                                  <div className="primary-easy-wrapper">
                                    <p className="primary-easy-text primary-easy-compact">
                                      {orderStats?.totalOrders || '-'}
                                      {/* Total collection this month: 12 */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-info enable-link">
                        <div className="hover-container">
                          <div className="layout-button">
                            <div className="layout-easy compact">
                              <div className="header-easy">
                                <div className="title-easy">
                                  <div className="title-header-easy">
                                    <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                      Total Collection
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-content">
                                <div className="content-flex-wrapper">
                                  <div className="primary-easy-wrapper">
                                    <p className="primary-easy-text primary-easy-compact">
                                      {orderStats?.totalCollections || '-'}
                                      {/* Total collection last month: 12 */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-info enable-link">
                        <div className="hover-container">
                          <div className="layout-button">
                            <div className="layout-easy compact">
                              <div className="header-easy">
                                <div className="title-easy">
                                  <div className="title-header-easy">
                                    <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                      Total Returns
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-content">
                                <div className="content-flex-wrapper">
                                  <div className="primary-easy-wrapper">
                                    <p className="primary-easy-text primary-easy-compact">
                                      {orderStats?.totalReturn || '-'}
                                      {/* Pods in storage now: 44 */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-info enable-link">
                        <div className="hover-container">
                          <div className="layout-button">
                            <div className="layout-easy compact">
                              <div className="header-easy">
                                <div className="title-easy">
                                  <div className="title-header-easy">
                                    <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                      Total Access
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-content">
                                <div className="content-flex-wrapper">
                                  <div className="primary-easy-wrapper">
                                    <p className="primary-easy-text primary-easy-compact">
                                      {orderStats?.totalAccess || '-'}
                                      {/* Containers in storage now: 23 */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="container-info enable-link">
                            <div className="hover-container">
                              <div className="layout-button">
                                <div className="layout-easy compact">
                                  <div className="header-easy">
                                    <div className="title-easy">
                                      <div className="title-header-easy">
                                        <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                          Delivered orders over time
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="panel-content">
                                    <div className="content-flex-wrapper">
                                      <div className="primary-easy-wrapper">
                                        <p className="primary-easy-text primary-easy-compact">
                                          0
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-info enable-link">
                            <div className="hover-container">
                              <div className="layout-button">
                                <div className="layout-easy compact">
                                  <div className="header-easy">
                                    <div className="title-easy">
                                      <div className="title-header-easy">
                                        <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                          Time to fulfill
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="panel-content">
                                    <div className="content-flex-wrapper">
                                      <div className="primary-easy-wrapper">
                                        <p className="primary-easy-text primary-easy-compact">
                                          17 hr 30 min
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-layout-section">
        <div className="panel-shadowBevel">
          <div className="panel-box">
            <div className="content-wrapper">
              <div style={{ minWidth: '11rem' }}>
                {/* <Dropdown as={ButtonGroup} className="position-static">
                      <Dropdown.Toggle
                        variant="phoenix-secondary"
                        style={{ padding: '30px 0px' }}
                        className="px-7 flex-shrink-0 dropdown-caret-none"
                      >
                        30 days
                        <FontAwesomeIcon
                          icon={faAngleDown}
                          className="ms-2"
                          transform="down-2"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>Today</Dropdown.Item>
                        <Dropdown.Item>Last 7 days</Dropdown.Item>
                        <Dropdown.Item>Last 30 days</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
              </div>
              <div
                className="panel-box"
                style={{ width: '100%', position: 'relative', zIndex: 1 }}
              >
                <div className="horizontal-navigation">
                  <div className="container-tab" tabIndex={0}>
                    <div className="panel-inline-stack">
                      <div className="container-info enable-link">
                        <div className="hover-container">
                          <div className="layout-button">
                            <div className="layout-easy compact">
                              <div className="header-easy">
                                <div className="title-easy">
                                  <div className="title-header-easy">
                                    <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                      Total collection this month
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-content">
                                <div className="content-flex-wrapper">
                                  <div className="primary-easy-wrapper">
                                    <p className="primary-easy-text primary-easy-compact">
                                      {orderStats?.collectionsThisMonth || '-'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-info enable-link">
                        <div className="hover-container">
                          <div className="layout-button">
                            <div className="layout-easy compact">
                              <div className="header-easy">
                                <div className="title-easy">
                                  <div className="title-header-easy">
                                    <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                      Total collection last month
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-content">
                                <div className="content-flex-wrapper">
                                  <div className="primary-easy-wrapper">
                                    <p className="primary-easy-text primary-easy-compact">
                                      {orderStats?.collectionsPreviousMonth ||
                                        '-'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-info enable-link">
                        <div className="hover-container">
                          <div className="layout-button">
                            <div className="layout-easy compact">
                              <div className="header-easy">
                                <div className="title-easy">
                                  <div className="title-header-easy">
                                    <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                      Pods in storage now
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-content">
                                <div className="content-flex-wrapper">
                                  <div className="primary-easy-wrapper">
                                    <p className="primary-easy-text primary-easy-compact">
                                      {orderStats?.podsInStorage || '-'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-info enable-link">
                        <div className="hover-container">
                          <div className="layout-button">
                            <div className="layout-easy compact">
                              <div className="header-easy">
                                <div className="title-easy">
                                  <div className="title-header-easy">
                                    <h2 className="title-text-root title-text-bodysm title-text-block title-text-truncate">
                                      Containers in storage now
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className="panel-content">
                                <div className="content-flex-wrapper">
                                  <div className="primary-easy-wrapper">
                                    <p className="primary-easy-text primary-easy-compact">
                                      {orderStats?.containerInStorage || '-'}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrdersPanel;
