/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import DealCard from '../card/dealCard';
import NoRecord from '../card/noRecord';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Spinner } from 'react-bootstrap';
import { updateDealStatus } from '../../../../redux/features/Deals/dealsApi';
// import { dealStatus } from '../_redux/deals/dealsCrud';

interface DealsGridProps {
  entities: any;
}
const DealsGrid: React.FC<DealsGridProps> = ({ entities }) => {
  const [items, setItems] = useState([]);
  const dispatch = useAppDispatch();
  const { currentState, permission } = useSelector(
    (state: RootState) => ({
      currentState: state.deals.allList,
      permission: state.common.permission.data
    }),
    shallowEqual
  );

  const onDragEnd = (result: any) => {
    if (result && result.destination && result.destination.droppableId) {
      const currentId: any = items.findIndex(
        (element: any) => element.id === Number(result.draggableId)
      );

      const arr: any = [...items];
      const temp_element = { ...arr[currentId] };

      temp_element.deal_status = result.destination.droppableId;
      arr.splice(currentId, 1);
      arr.unshift(temp_element);
      setItems(arr);
      const data = {
        id: result.draggableId,
        status: result.destination.droppableId
      };
      dispatch(updateDealStatus(data));
      // dealStatus(data).then(res => {});
    }
  };

  useEffect(() => {
    if (!currentState.isLoading) {
      setItems(entities);
    }
  }, [currentState.isLoading, entities, entities?.length]);

  return (
    <>
      {currentState.isLoading ? (
        <div className="d-flex mb-3" style={{ marginTop: '6px' }}>
          <Spinner animation="border" variant="primary" className="mt-2" />
          <span
            className="ml-2"
            style={{ paddingTop: '16px', marginLeft: '4px' }}
          >
            loading...
          </span>
        </div>
      ) : (
        <>
          {items && items.length ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <div
                className="row"
                style={{
                  pointerEvents:
                    permission?.deal.create || permission?.deal.create
                      ? 'auto'
                      : 'none'
                }}
              >
                <div className="col-md-12 pt-3">
                  <div className="row deals-grid">
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">Open</div>
                          </header>
                        </div>

                        <Droppable droppableId="Open">
                          {(provided: any) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items?.length
                                ? items?.map((item: any, index: any) => (
                                    <Fragment key={index}>
                                      {item.deal_status === 'Open' ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                        >
                                          {(provided: any, snapshot: any) => (
                                            <div
                                              ref={provided.innerRef}
                                              // eslint-disable-next-line react/no-unknown-property
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ''
                                      )}
                                    </Fragment>
                                  ))
                                : ''}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">Transit</div>
                          </header>
                        </div>

                        <Droppable droppableId="Transit">
                          {(provided: any) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item: any, index: any) => (
                                    <Fragment key={index}>
                                      {item.deal_status === 'Transit' ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                        >
                                          {(provided: any, snapshot: any) => (
                                            <div
                                              ref={provided.innerRef}
                                              // eslint-disable-next-line react/no-unknown-property
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ''
                                      )}
                                    </Fragment>
                                  ))
                                : ''}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">Pipeline</div>
                          </header>
                        </div>

                        <Droppable droppableId="Pipeline">
                          {(provided: any) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item: any, index: any) => (
                                    <Fragment key={index}>
                                      {item.deal_status === 'Pipeline' ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                        >
                                          {(provided: any, snapshot: any) => (
                                            <div
                                              ref={provided.innerRef}
                                              // eslint-disable-next-line react/no-unknown-property
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ''
                                      )}
                                    </Fragment>
                                  ))
                                : ''}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">Close-Won</div>
                          </header>
                        </div>

                        <Droppable droppableId="Close-Won">
                          {(provided: any) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item: any, index: any) => (
                                    <Fragment key={index}>
                                      {item.deal_status === 'Close-Won' ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                          // isDragDisabled
                                        >
                                          {(provided: any, snapshot: any) => (
                                            <div
                                              ref={provided.innerRef}
                                              // eslint-disable-next-line react/no-unknown-property
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ''
                                      )}
                                    </Fragment>
                                  ))
                                : ''}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="kanban-board">
                        <div className="kanban-board-header">
                          <header>
                            <div className="kanban-title-board">Close-Lost</div>
                          </header>
                        </div>

                        <Droppable droppableId="Close-Lost">
                          {(provided: any) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {items && items.length
                                ? items.map((item: any, index: any) => (
                                    <Fragment key={index}>
                                      {item.deal_status === 'Close-Lost' ? (
                                        <Draggable
                                          key={String(item.id)}
                                          draggableId={String(item.id)}
                                          index={index}
                                          // isDragDisabled
                                        >
                                          {(provided: any, snapshot: any) => (
                                            <div
                                              ref={provided.innerRef}
                                              // eslint-disable-next-line react/no-unknown-property
                                              snapshot={snapshot}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="kanban-drag"
                                            >
                                              <DealCard item={item} />
                                            </div>
                                          )}
                                        </Draggable>
                                      ) : (
                                        ''
                                      )}
                                    </Fragment>
                                  ))
                                : ''}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DragDropContext>
          ) : (
            <NoRecord />
          )}
        </>
      )}
    </>
  );
};

export default DealsGrid;
