import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/Table/tasksFilter';
import { makeRequest } from 'helpers/apiCall';

export const getTasksList = createAsyncThunk(
  'tasks/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `task?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}&search_type=${queryParams.filter.search_type}&search_view=${queryParams.filter.search_view}&search_owner=${queryParams.filter.search_owner}&search_stage=${queryParams.filter.search_stage}&search_franchise=${queryParams.filter.search_franchise}&search_priority=${queryParams.filter.search_priority}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
