import React from 'react';

import { Card } from 'react-bootstrap';
import { Dropdown, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
export const OrderBilling = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <Card className="p-3 mb-3">
      <div className="calendarHead">
        <div className="subHead">
          <h4>Billing </h4>
          <Form.Check
            type="switch"
            id="defaultDisableSwitch"
            className="ms-2"
          />
        </div>
        <ul>
          <li>
            <Dropdown>
              <Dropdown.Toggle variant="phoenix-primary">
                Action
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
      <div className="billingTable">
        <Table responsive>
          <tbody>
            <tr>
              <td>Pod:</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Number of Pods Initially Booked:</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Number of Pods Collected :</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Number of Pods Currently in Store:</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Payment Status:</td>
              <td>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-success"
                />
              </td>
            </tr>
            <tr>
              <td>Recurring Cycle:</td>
              <td>
                {' '}
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-success"
                />
              </td>
            </tr>
            <tr>
              <td>Invoice Date:</td>
              <td>28-12-2023</td>
            </tr>
            <tr>
              <td>Next Billing Amount:</td>
              <td>£105.95</td>
            </tr>
            <tr>
              <td>Next Billing Period:</td>
              <td>28-01-24-28-02-24</td>
            </tr>
            <tr>
              <td>Payment Method:</td>
              <td>Card</td>
            </tr>
            <tr>
              <td>Auto Collection:</td>
              <td>Same as Customer (On)</td>
            </tr>
            <tr>
              <td>Billing Address:</td>
              <td>
                Flat 89, Blenheim Mansions, 3Mary Neuner Road, London, Greater
                London
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Card>
  );
};
