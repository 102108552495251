/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import {
  getActiveCountry,
  getCountryFranchiseList,
  getProfileList
} from '../../../../../redux/features/Common/commonApi';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { useFormik } from 'formik';
import ReactSelect from 'components/base/ReactSelect';
import { useSelector } from 'react-redux';

export const UserForm = () => {
  const dispatch = useAppDispatch();
  const countryList = useSelector(
    (state: RootState) => state.common.countrylist.data
  );
  const profileList = useSelector(
    (state: RootState) => state.common.profilelist.data
  );
  const countryFranchiseList = useSelector(
    (state: RootState) => state.common.countryFranchiselist.data
  );
  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    password: '',
    phone: '',
    franchise_id: '',
    country_id: '',
    profile_id: '',
    status: 0,
    is_available: '0'
  };
  const handleSubmit = () => {
    console.log();
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });
  useEffect(() => {
    dispatch(getActiveCountry());
    dispatch(getProfileList());
  }, [dispatch]);
  useEffect(() => {
    if (formik.values.country_id !== '') {
      dispatch(getCountryFranchiseList(formik.values.country_id));
    }
  }, [formik.values.country_id]);

  return (
    <div>
      {' '}
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row className="mb-3 gx-3">
          <Form.Group as={Col} md="4" controlId="exampleFirstName">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First name"
              {...formik.getFieldProps('first_name')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="exampleLastName">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Last name"
              {...formik.getFieldProps('last_name')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="exampleLastName">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Email"
              {...formik.getFieldProps('email')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="exampleLastName">
            <Form.Label>Username</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Username"
              {...formik.getFieldProps('username')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="exampleLastName">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Password"
              {...formik.getFieldProps('password')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Country</Form.Label>
            <ReactSelect
              options={[
                { value: '', label: 'Select' }, // Blank option with label 'Select'
                ...(countryList?.data.map((e: any) => ({
                  value: e.id,
                  label: e.name
                })) || [])
              ]}
              placeholder="Select organizer..."
              onChange={(e: any) => formik.setFieldValue('country_id', e.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Profile</Form.Label>
            <ReactSelect
              options={[
                { value: '', label: 'Select' }, // Blank option with label 'Select'
                ...(profileList?.data.map((e: any) => ({
                  value: e.id,
                  label: e.name
                })) || [])
              ]}
              placeholder="Select organizer..."
              onChange={(e: any) => formik.setFieldValue('profile_id', e.value)}
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>Franchise</Form.Label>
            <ReactSelect
              options={[
                { value: '', label: 'Select' }, // Blank option with label 'Select'
                ...(countryFranchiseList?.data.map((e: any) => ({
                  value: e.id,
                  label: e.name
                })) || [])
              ]}
              placeholder="Select organizer..."
              onChange={(e: any) =>
                formik.setFieldValue('franchise_id', e.value)
              }
              isDisabled={formik?.values?.profile_id == '1'}
            />
          </Form.Group>
        </Row>

        <Button type="submit">Submit form</Button>
      </Form>
    </div>
  );
};
