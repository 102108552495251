/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UilGlobe,
  UilPhone,
  UilUser,
  UilUserNurse,
  UilEnvelopeAlt
} from '@iconscout/react-unicons';
import classNames from 'classnames';
import Button from 'components/base/Button';
import LeadInfoItem from 'components/info-items/LeadInfoItem';
import { Card } from 'react-bootstrap';

const UserCard = ({ className, user }: { className?: string; user?: any }) => {
  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex align-items-center mb-1">
          <h3>
            {user?.first_name} {user && user.last_name ? user.last_name : ''}
          </h3>
          <Button variant="link" className="px-3">
            Edit
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <p
            className={`text-${
              user && user.status && user.status === 1 ? 'success' : 'danger'
            } font-weight-bold mb-0 text-800`}
          >
            {user && user.status && user.status === 1 ? 'Active' : 'Inactive'}
          </p>
        </div>
        <div className="d-flex align-items-center mb-5">
          <p className="font-weight-bold mb-0 text-800">
            {user && user.is_available && user.is_available === 1
              ? 'Available'
              : 'Unavailable'}
            {}
          </p>
        </div>
        <LeadInfoItem className="mb-4" label="User ID" icon={UilUser}>
          <p className="mb-0 text-800">{user?.id || '-'}</p>
        </LeadInfoItem>
        <LeadInfoItem className="mb-4" label="Profile" icon={UilUserNurse}>
          <p className="mb-0 text-800">
            {user && user.profile
              ? user.profile.charAt(0).toUpperCase() +
                user.profile.slice(1).replace(/-/g, ' ')
              : '-'}
          </p>
        </LeadInfoItem>
        <LeadInfoItem className="mb-4" label="Franchises" icon={UilGlobe}>
          <p className="mb-0 text-800">
            {user && user.franchise ? user.franchise : '-'}
          </p>
        </LeadInfoItem>
        <LeadInfoItem className="mb-4" label="Email" icon={UilEnvelopeAlt}>
          <p className="mb-0 text-800">{user?.email}</p>
        </LeadInfoItem>
        <LeadInfoItem className="mb-4" label="Phone" icon={UilPhone}>
          <p className="mb-0 text-800">{user?.phone}</p>
        </LeadInfoItem>
      </Card.Body>
    </Card>
  );
};

export default UserCard;
