/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import CustomPagination from 'components/base/CustomPagination';
import moment from 'moment';
import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';
interface Orders {
  booking_reference: string;
  amount: string;
  type: string;
  is_cancel: number;
  is_container: number;
  is_return: number;
  is_same_time_return: number;
  is_student_access: number;
  is_student_return: number;
  fulfillment_stage: string;
  customer_details: {
    first_name: string;
    last_name: string;
  };
  pod: string;
  franchise_details: {
    name: string;
    short_name: string;
  };
  created_by_name: string;
  contact_owner_name: string;
  collection_date: string;
  created_at: string;
  payment_status: string;
}
interface OrdersTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}
export const ordersTableColumns: ColumnDef<Orders>[] = [
  {
    accessorKey: 'booking_reference',
    header: 'ORDER NUMBER',
    cell: ({ row: { original } }) => {
      const { booking_reference } = original;
      return <>{booking_reference}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'amount',
    header: 'TOTAL',
    cell: ({ row: { original } }) => {
      const { amount } = original;
      return <>£{amount}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'customer_details',
    header: 'CUSTOMER NAME',
    cell: ({ row: { original } }) => {
      const { customer_details } = original;
      return (
        <>
          {customer_details.first_name}&nbsp;{customer_details.last_name}
        </>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'created_at',
    header: 'ORDER DATE & TIME',
    cell: ({ row: { original } }) => {
      const { created_at } = original;
      return <>{moment(created_at).format('DD-MM-YYYY HH:MM A')}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'type',
    header: 'TYPE',
    cell: ({ row: { original } }) => {
      const { type } = original;
      return <>{type}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'payment_status',
    header: 'FULFILMENT STATUS',
    cell: ({ row: { original } }) => {
      return (
        <Badge
          bg={
            original?.is_same_time_return === 1
              ? 'warning'
              : original?.is_cancel === 1
              ? 'danger'
              : original?.is_student_access === 1
              ? 'primary'
              : original?.is_student_access !== 1 && original?.is_return === 1
              ? 'danger'
              : 'success'
          }
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
          icon={
            <FeatherIcon icon={'feather-check'} size={12.8} className="ms-1" />
          }
        >
          {original?.is_same_time_return === 1
            ? 'Col-Ret'
            : original?.is_cancel === 1
            ? 'Cancelled'
            : original?.is_student_access === 1
            ? 'Access'
            : original?.is_student_access !== 1 && original?.is_return === 1
            ? 'Return'
            : 'Collection'}
        </Badge>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'franchise_details',
    header: 'FRANCHISE',
    cell: ({ row: { original } }) => {
      const { franchise_details } = original;
      return <>{franchise_details.short_name}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'contact_owner_name',
    header: 'CLOSED BY',
    cell: ({ row: { original } }) => {
      const { contact_owner_name } = original;
      return <>{contact_owner_name}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  }
  // {
  //   accessorKey: 'pod',
  //   header: 'PODS',
  //   cell: ({ row: { original } }) => {
  //     const { pod } = original;
  //     return <>{pod}</>;
  //   },
  //   meta: {
  //     headerProps: { className: 'pe-3 ps-0' },
  //     cellProps: { className: 'ps-0' }
  //   }
  // },
  // {
  //   accessorKey: 'created_by_name',
  //   header: 'CREATED BY',
  //   cell: ({ row: { original } }) => {
  //     const { created_by_name } = original;
  //     return <>{created_by_name}</>;
  //   },
  //   meta: {
  //     headerProps: { className: 'pe-3 ps-0' },
  //     cellProps: { className: 'ps-0' }
  //   }
  // },

  // {
  //   accessorKey: 'collection_date',
  //   header: 'COLLECTION DATE',
  //   cell: ({ row: { original } }) => {
  //     const { collection_date } = original;
  //     return <>{moment(collection_date).format('DD-MM-YYYY')}</>;
  //   },
  //   meta: {
  //     headerProps: { className: 'pe-3 ps-0' },
  //     cellProps: { className: 'ps-0' }
  //   }
  // }
];

const OrdersTable: React.FC<OrdersTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default OrdersTable;
