/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequest } from 'helpers/apiCall';

export const getTimelineList = createAsyncThunk('timeline/list', async () => {
  try {
    const response1 = await makeRequest('GET', `timeline/profile/list`);
    const response2 = await makeRequest(
      'GET',
      `timeline/get-selected-timelines`
    );
    const response3 = await makeRequest('GET', `user-permissions-list`);

    // Extract the data from the responses
    const data1 = response1.data;
    const data2 = response2.data;
    const data3 = response3.data;
    return { data1, data2, data3 };
  } catch (error) {
    console.log(error);
  }
});

export const getFeedsList = createAsyncThunk('timeline/feeds', async () => {
  try {
    const response = await makeRequest(
      'GET',
      `timeline/get-selected-timelines`
    );
    const feedsData = response.data;
    return feedsData;
  } catch (error) {
    console.log(error);
  }
});

export const feedsCreate = async (data: any) => {
  const response = await makeRequest('POST', 'timeline', data);
  return response;
};

export const feedsUpdate = async (postData: any) => {
  const response = await makeRequest(
    'POST',
    `timeline/${postData?.id}`,
    postData.data
  );
  return response;
};
