import AboutLeadCard from 'components/cards/AboutLeadCard';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getPromotionsDetail } from '../../../../redux/features/Settings/Promotions/promotionsApi';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
const PromotionsDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const promotion = useSelector(
    (state: RootState) => state.promotion.detail.data
  );

  useEffect(() => {
    if (id) {
      dispatch(getPromotionsDetail(id));
    }
  }, [id]);

  return (
    <div>
      <AboutLeadCard className="mb-3" promotion={promotion?.data} />
    </div>
  );
};

export default PromotionsDetail;
