/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

// Define the type for the axios instance configuration
type AxiosInstanceConfig = AxiosRequestConfig;

// Create the Axios instance with a base URL
const axiosInstance = axios.create({
  baseURL: 'https://phoenixapi.easystorage.com/api', // Replace with your API base URL
  timeout: 5000 // Adjust as needed
});

// Function to make a generic HTTP request
const makeRequest = <T,>(
  method: string,
  url: string,
  data: Record<string, any> = {},
  config: AxiosInstanceConfig = {}
): Promise<AxiosResponse<T>> => {
  const fullURL = `${axiosInstance.defaults.baseURL}/${url}`;

  // Assuming you have a token variable available, replace 'YOUR_TOKEN_HERE' with your actual token.
  const token = Cookies.get('token');

  // Add the 'Authorization' header with the token value to the config object.
  config.headers = {
    Authorization: `${token}`,
    ...config.headers // Preserve any existing headers in the config object.
  };

  return axiosInstance({
    method,
    url: fullURL,
    data,
    ...config
  });
};

export { makeRequest };
