import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAgentsList } from './agentsApi';

interface AgentsState {
  list: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
}

const initialState: AgentsState = {
  list: {
    isLoading: false,
    data: {
      data: [],
      meta: {}
    },
    isError: false
  }
};

export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAgentsList.pending, state => {
      state.list.isLoading = true;
      state.list.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getAgentsList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
        state.list.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getAgentsList.rejected, state => {
      state.list.isLoading = false;
      state.list.isError = true;
    });
  }
});

export default agentsSlice.reducer;
