/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { useNavigate } from 'react-router-dom';

interface CustomPaginationProps {
  dataPerPage: number;
  totalData: number;
  //   setQueryParamsBase: (params: any) => void; // Replace 'any' with the actual type of queryParams
  currentPage: number;
  nextoffset: any;
  prevoffset: any;
  //   queryParams: any; // Replace 'any' with the actual type of queryParams
}

const PromotionPagination: React.FC<CustomPaginationProps> = ({
  //   setQueryParamsBase,
  currentPage,
  nextoffset
}) => {
  const history = useNavigate();
  return (
    <Pagination style={{ marginTop: '10px' }}>
      {/* <Pagination.First
        onClick={() => history('?page=1')}
        disabled={currentPage === 1}
      /> */}
      <Pagination.Prev
        onClick={() => history(`?page=${currentPage - 1}`)}
        // disabled={currentPage === 1}
      />
      {/* {start !== 1 && <Pagination.Ellipsis />} */}

      <Pagination.Item
        key={currentPage}
        onClick={() => history(`?page=${currentPage}`)}
        active={currentPage === currentPage}
      >
        {currentPage}
      </Pagination.Item>

      {/* {end !== pageNumbers.length && <Pagination.Ellipsis />} */}
      <Pagination.Next
        onClick={() => history(`?page=${currentPage + 1}&offset=${nextoffset}`)}
        // disabled={currentPage === pageNumbers.length}
      />
      {/* <Pagination.Last
        onClick={
          () => history('?&pageSize=10')
          // setQueryParamsBase({ ...queryParams, pageNumber: pageNumbers.length })
        }
        disabled={currentPage === pageNumbers.length}
      /> */}
    </Pagination>
  );
};

export default PromotionPagination;
