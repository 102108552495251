/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CustomPagination from 'components/base/CustomPagination';

interface Franchises {
  franchise_reference_id: string;
  name: string;
  short_name: string;
  phone: string;
  email: string;
}

interface FranchisesTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}

export const franchisesTableColumns: ColumnDef<Franchises>[] = [
  {
    accessorKey: 'franchise_reference_id',
    header: 'FRANCHISE ID',
    cell: ({ row: { original } }) => {
      const { franchise_reference_id } = original;
      return <>{franchise_reference_id}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'name',
    header: 'FRANCHISE NAME',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return <>{name}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'short_name',
    header: 'FRANCHISE SHORT',
    cell: ({ row: { original } }) => {
      const { short_name } = original;
      return <>{short_name}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'phone',
    header: 'PHONE',
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return <>{phone}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'email',
    header: '	EMAIL',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return <>{email}</>;
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  }
];

const FranchisesTable: React.FC<FranchisesTableProps> = ({
  paginationProps
}) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default FranchisesTable;
