import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/Table/ordersFilter';
import { makeRequest } from 'helpers/apiCall';

export const getOrdersList = createAsyncThunk(
  'orders/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `order?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}&search_view=${queryParams.filter.search_view}&search_agent=${queryParams.filter.search_agent}&search_franchise=${queryParams.filter.search_franchise}&search_status=${queryParams.filter.status}&from_date=${queryParams.filter.from_date}&to_date=${queryParams.filter.to_date}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getOrdersStats = createAsyncThunk(
  'orders/order-stats',
  async () => {
    try {
      const response = await makeRequest('GET', `order/order-stats`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
