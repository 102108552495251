/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/Table/dealsFilter';
import { makeRequest } from 'helpers/apiCall';

export const getDealsList = createAsyncThunk(
  'deals/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `deal?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}&search_view=${queryParams.filter.search_view}&search_agent=${queryParams.filter.search_agent}&search_franchise=${queryParams.filter.search_franchise}&search_status=${queryParams.filter.search_status}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getDealsGridList = createAsyncThunk(
  'all-deals/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `deal/all-deals/list?search_keyword=${queryParams.filter.search}&search_view=${queryParams.filter.search_view}&search_agent=${queryParams.filter.search_agent}&search_franchise=${queryParams.filter.search_franchise}&search_status=${queryParams.filter.search_status}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const updateDealStatus = createAsyncThunk(
  'deal/update-deals-status',
  async (data: any) => {
    try {
      const response = await makeRequest(
        'POST',
        `deal/update-deals-status`,
        data
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
