/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CustomPagination from 'components/base/CustomPagination';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';

interface Contacts {
  user_data: {
    first_name: string;
    last_name: string;
  };
  email: string;
  phone: string;
  agent_data: {
    first_name: string;
  };
  source: string;
  last_activity_date: string;
  created_at: string;
}

interface ContactsTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}

export const contactsTableColumns: ColumnDef<Contacts>[] = [
  {
    accessorKey: 'user_data',
    header: 'CONTACT NAME',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original.user_data.first_name}&nbsp;{original.user_data.last_name}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'email',
    header: 'EMAIL',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original.email && original.email?.length && original.email[0]
            ? original.email[0]
            : '-'}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'phone',
    header: 'PHONE',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original.phone && original.phone?.length && original.phone[0]
            ? original.phone[0]
            : '-'}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'agent_data',
    header: 'AGENT',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original && original.agent_data && original.agent_data.first_name
            ? original.agent_data.first_name
            : ' - '}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'source',
    header: 'SOURCE',
    cell: ({ row: { original } }) => {
      return <>{(original && original.source) || '-'}</>;
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'last_activity_date',
    header: 'LAST ACTIVITY DATE',
    cell: ({ row: { original } }) => {
      return <>{moment(original.last_activity_date).format('DD-MM-YYYY')}</>;
    },
    meta: {
      headerProps: { style: { width: '17%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'created_at',
    header: 'CREATE DATE',
    cell: ({ row: { original } }) => {
      return <>{moment(original.created_at).format('DD-MM-YYYY')}</>;
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    id: 'view',
    header: 'VIEW',
    cell: () => <FeatherIcon icon="eye" style={{ cursor: 'pointer' }} />,
    meta: {
      headerProps: {
        className: 'text-end ps-4'
      },
      cellProps: { className: 'text-end ps-4 py-0' }
    }
  }
];

const ContactsTable: React.FC<ContactsTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default ContactsTable;
