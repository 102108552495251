/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterButtonGroup from 'components/common/FilterButtonGroup';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useMemo, useState } from 'react';
// import { getCountryList } from './service';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import {
  getAgentsList,
  getFranchiseList
} from '../../../../redux/features/Common/commonApi';
import { filterMenus } from '../components/tableFilters/filterMenus';
import { getTasksList } from '../../../../redux/features/Tasks/tasksApi';
import TasksTable, { tasksTableColumns } from 'components/tables/TasksTable';

const TasksList = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const tasks = useSelector((state: RootState) => state.tasks.list.data);
  const franchiseList = useSelector(
    (state: RootState) => state.common.franchiselist.data
  );
  const isLoading = useSelector(
    (state: RootState) => state.tasks.list.isLoading
  );
  const agentList = useSelector(
    (state: RootState) => state.common.agentlist.data
  );
  const [filter, setFilter] = useState({
    search: '',
    search_franchise: '',
    search_view: '',
    search_agent: '',
    search_status: '',
    search_type: '',
    search_owner: '',
    search_stage: '',
    search_priority: ''
  });

  const filterMenu = filterMenus(franchiseList, agentList);

  const table = useAdvanceTable({
    data: tasks.data,
    columns: tasksTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(tasks?.meta?.total),
      current_page: Number(tasks?.meta?.current_page),
      isLoading: isLoading
    };
  }, [location, tasks.meta, isLoading]);

  const handleTaskList = async (): Promise<void> => {
    dispatch(
      getTasksList({
        pageNumber: paginationProps?.page,
        pageSize: paginationProps?.dataPerPage,
        sortField: '',
        sortOrder: '',
        filter: filter
      })
    );
  };
  useEffect(() => {
    handleTaskList();
  }, [location, filter]);

  useEffect(() => {
    dispatch(getFranchiseList());
    dispatch(getAgentsList());
  }, [dispatch]);

  const handleFilter = (key: any, value: any) => {
    setFilter({ ...filter, [key]: value });
  };

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Tasks</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <div className="scrollbar overflow-hidden-y">
                <FilterButtonGroup
                  menus={filterMenu}
                  filter={filter}
                  handleFilter={handleFilter}
                />
              </div>
              <SearchBox
                placeholder="Search tasks"
                onChange={e => handleFilter('search', e.target.value)}
              />
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <TasksTable paginationProps={paginationProps} />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default TasksList;
