import React from 'react';

import { Card } from 'react-bootstrap';
export const OrderTimeline = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <Card className="p-3 mb-3">
      <div className="calendarHead">
        <div className="subHead">
          <h4>Timeline </h4>
        </div>
      </div>
    </Card>
  );
};
