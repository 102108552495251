/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterMenu } from 'components/common/FilterButtonGroup';

export const filterMenus = (franchiseList: any, agentList: any) => {
  const franchiseItems = franchiseList.data.map((franchise: any) => ({
    label: franchise.name,
    id: franchise.id.toString()
  }));

  const agentItems = agentList.data.map((agent: any) => ({
    label: agent.first_name,
    id: agent.id.toString()
  }));

  const all = [{ id: '', label: 'All' }];

  const owner = [
    { id: '', label: 'All' },
    { id: 'is_unassigned', label: 'Unassigned' }
  ];

  const viewTaskList = [
    { id: 'all_tasks', label: 'All Tasks' },
    { id: 'my_tasks', label: 'My Tasks' },
    { id: 'new_last_week', label: 'New Last Week' },
    { id: 'new_this_week', label: 'New This Week' }
  ];

  const taskType = [
    { id: '', label: 'All' },
    { id: 'Call', label: 'Call' },
    { id: 'Email', label: 'Email' },
    { id: 'SMS', label: 'SMS' }
  ];

  const priority = [
    { id: '', label: 'All' },
    { id: 'Hot', label: 'Hot' },
    { id: 'Medium', label: 'Medium' },
    { id: 'Low', label: 'Low' }
  ];

  const taskStage = [
    { id: '', label: 'All' },
    { id: 'contact', label: 'Contact' },
    { id: 'deal', label: 'Deals' },
    { id: 'customer', label: 'Customer' }
  ];

  const filterMenus: FilterMenu[] = [
    {
      label: 'View',
      id: 'search_view',
      items: viewTaskList
    },
    {
      label: 'Task type',
      id: 'search_type',
      items: taskType
    },
    {
      label: 'Priority',
      id: 'search_priority',
      items: priority
    },
    {
      label: 'Task Owners',
      id: 'search_owner',
      items: [...owner, ...agentItems]
    },
    {
      label: 'Franchise',
      id: 'search_franchise',
      items: [...all, ...franchiseItems]
    },
    {
      label: 'Stage',
      id: 'search_stage',
      items: taskStage
    }
  ];

  return filterMenus;
};
