import React from 'react';

import { OrderHead } from 'components/order/order-head';
import { OrderDetailInfo } from 'components/order/order-detail';
import { OrderNotes } from 'components/order/order-notes';
import { OrderCustomer } from 'components/order/order-customer';
// import { ConversionSummary } from 'components/order/conversion-summary';
// import { FraudAnalysis } from 'components/order/fraud-analysis';
import { OrderCalendar } from 'components/order/order-calendar';
import { OrderBilling } from 'components/order/order-billing';
import { OrderPayment } from 'components/order/order-payment';
import { OrderTimeline } from 'components/order/order-timeline';
export const OrderDetailPage = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <div className="order">
      {/* Order Header Start */}
      <OrderHead />
      {/* Order Header Section End */}
      <div className="row">
        <div className="col-lg-9">
          <OrderDetailInfo />
          <OrderCalendar />
          <div className="row">
            <div className="col-lg-4">
              <OrderBilling />
            </div>
            <div className="col-lg-4">
              <OrderPayment />
            </div>
            <div className="col-lg-4">
              <OrderTimeline />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <OrderCustomer />
          <OrderNotes />

          {/* <ConversionSummary />
          <FraudAnalysis /> */}
        </div>
      </div>
    </div>
  );
};
