import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDealsGridList, getDealsList, updateDealStatus } from './dealsApi';

interface DealsState {
  list: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
  allList: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
  dealStatus: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
}

const initialState: DealsState = {
  list: {
    isLoading: false,
    data: {
      data: [],
      meta: {}
    },
    isError: false
  },
  allList: {
    isLoading: false,
    data: [],
    isError: false
  },
  dealStatus: {
    isLoading: false,
    data: [],
    isError: false
  }
};

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDealsList.pending, state => {
      state.list.isLoading = true;
      state.list.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getDealsList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
        state.list.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getDealsList.rejected, state => {
      state.list.isLoading = false;
      state.list.isError = true;
    });
    //all deal list
    builder.addCase(getDealsGridList.pending, state => {
      state.allList.isLoading = true;
      state.allList.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getDealsGridList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.allList.isLoading = false;
        state.allList.data = action.payload;
        state.allList.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getDealsGridList.rejected, state => {
      state.allList.isLoading = false;
      state.allList.isError = true;
    });
    //update deal status
    builder.addCase(updateDealStatus.pending, state => {
      state.dealStatus.isLoading = true;
      state.dealStatus.isError = false; // Reset isError on pending
    });
    builder.addCase(
      updateDealStatus.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.dealStatus.isLoading = false;
        state.dealStatus.data = action.payload;
        state.dealStatus.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(updateDealStatus.rejected, state => {
      state.dealStatus.isLoading = false;
      state.dealStatus.isError = true;
    });
  }
});

export default dealsSlice.reducer;
