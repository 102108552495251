/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren, useState } from 'react';
import SearchBox, { SearchBoxProps } from './SearchBox';
import { Dropdown, Spinner } from 'react-bootstrap';
import { RootState, useAppDispatch } from '../../redux/store';
import { getGlobalSearch } from '../../redux/features/Common/commonApi';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';

interface DropdownSearchBoxProps extends SearchBoxProps {
  className?: string;
  searchBoxClassName?: string;
}

const DropdownSearchBox = ({
  children,
  className,
  searchBoxClassName,
  ...rest
}: PropsWithChildren<DropdownSearchBoxProps>) => {
  const dispatch = useAppDispatch();
  const globalSearch = useSelector(
    (state: RootState) => state.common.globalSearch.data
  );
  const loading = useSelector((state: RootState) => state.common.globalSearch);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');

  const groupedData = globalSearch?.data?.reduce((result: any, item: any) => {
    const source = item.source;

    if (!result[source]) {
      result[source] = [];
    }

    result[source].push(item);

    return result;
  }, {});
  return (
    <Dropdown
      className={className}
      onToggle={() => setOpenDropdown(!openDropdown)}
      show={openDropdown}
    >
      <Dropdown.Toggle as="div" aria-expanded={openDropdown} bsPrefix="toggle">
        <SearchBox
          placeholder="Search..."
          className={searchBoxClassName}
          // value={searchInputValue}
          onChange={e => {
            dispatch(getGlobalSearch(e.target.value));
            setSearchInputValue(e.target.value);
            setOpenDropdown(true);
          }}
          {...rest}
        />
      </Dropdown.Toggle>
      {children && (
        <Dropdown.Menu
          className="dropdown-menu border border-300 font-base start-0 py-0 overflow-auto w-100"
          style={{
            width: 400,
            height:
              !globalSearch.data?.length && loading.isLoading
                ? '12vh'
                : !globalSearch.data?.length
                ? '0vh'
                : '80vh'
          }}
        >
          {loading.isLoading ? (
            <div
              className="d-flex mb-3"
              style={{ marginTop: '6px', justifyContent: 'center' }}
            >
              <Spinner animation="border" variant="primary" className="mt-2" />
              <span
                className="ml-2"
                style={{ paddingTop: '16px', marginLeft: '4px' }}
              >
                loading...
              </span>
            </div>
          ) : (
            ''
          )}
          {groupedData &&
            Object.entries(groupedData).map(
              ([source, items]: [string, any]) => (
                <div key={source}>
                  <Dropdown.ItemText>
                    <h6
                      className="text-1000 fs-7 border-y border-200 py-2 lh-sm mb-0"
                      style={{ paddingLeft: '10px' }}
                    >
                      {source &&
                        source.charAt(0).toUpperCase() + source.slice(1)}
                    </h6>
                  </Dropdown.ItemText>

                  {items.map((item: any, index: any) => (
                    <>
                      <Dropdown.Item key={index}>
                        {item.source === 'activity_log' ? (
                          <span
                            className="py-2 d-flex gap-2 "
                            style={{ paddingLeft: '10px' }}
                          >
                            <div className="d-flex align-center">
                              <FeatherIcon icon="link-2" size={20} />
                            </div>
                            <div className="flex-1">
                              <h5 className="mb-0 text-1000">{item.name}</h5>
                              <p className="fs-12 mb-0 row text-700">
                                <span className="fw-medium text-600 col-8">
                                  {item.description}
                                </span>
                                {item.booking_reference && (
                                  <span className="fw-medium text-600 col-4">
                                    <FeatherIcon icon="link" size={14} />
                                    &nbsp; Job Id:&nbsp;
                                    {item.booking_reference}
                                  </span>
                                )}
                              </p>
                            </div>
                          </span>
                        ) : item.source === 'orders' ? (
                          <span
                            className="py-2 d-flex gap-2 "
                            style={{ margin: '0 0 -10px 10px' }}
                          >
                            <div className="d-flex align-center">
                              <FeatherIcon icon="shopping-cart" size={20} />
                            </div>
                            <div className="flex-1">
                              <h5 className="mb-0 text-1000">{item.name}</h5>
                              <p
                                className="fs-12 mb-0 row text-700"
                                style={{ justifyContent: 'space-between' }}
                              >
                                <span className="fw-medium text-600 col-4">
                                  {item.email}
                                </span>
                                <span className="fw-medium text-600 col-4">
                                  <FeatherIcon icon="link" size={14} />
                                  &nbsp;Job Id:&nbsp;
                                  {item.booking_reference}
                                </span>
                                <span
                                  className="fw-medium text-600 col-4 d-flex"
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon icon="calendar" size={14} />
                                  &nbsp;Collection Date:&nbsp;
                                  {item.collection_date}
                                </span>
                              </p>
                            </div>
                          </span>
                        ) : item.source === 'Storage details' ? (
                          <span
                            className="py-2 d-flex gap-2 "
                            style={{ margin: '0 0 -10px 10px' }}
                          >
                            <div className="d-flex align-center">
                              <FeatherIcon icon="shopping-cart" size={20} />
                            </div>
                            <div className="flex-1">
                              <h5 className="mb-0 text-1000 hello" id="hello">
                                {item.name}
                              </h5>
                              <p
                                className="fs-12 mb-0 row text-700"
                                style={{ justifyContent: 'space-between' }}
                              >
                                <span className="fw-medium text-600 col-4">
                                  {item.email}
                                </span>

                                <span className="fw-medium text-600 col-4 d-flex align-items-center">
                                  <FeatherIcon icon="link" size={14} />
                                  &nbsp;Job Id:&nbsp;
                                  {item.booking_reference}
                                </span>
                                <span
                                  className="fw-medium text-600 col-4 d-flex"
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon icon="calendar" size={14} />
                                  &nbsp;Collection Date:&nbsp;
                                  {item.collection_date}
                                </span>
                                <span className="fw-medium text-600 col-4 ">
                                  {item.phone}
                                </span>
                                <span className="fw-medium text-600 col-4 d-flex align-items-center">
                                  <FeatherIcon icon="hash" size={14} />
                                  &nbsp; Pod Number:&nbsp;
                                  {item.pod_number}
                                </span>
                                <span className="fw-medium text-600 col-4"></span>
                              </p>
                            </div>
                          </span>
                        ) : item.source === 'users' ? (
                          <span
                            className="py-2 d-flex gap-2 "
                            style={{ margin: '0 0 -10px 10px' }}
                          >
                            <div className="d-flex align-center">
                              <FeatherIcon icon="user" size={20} />
                            </div>
                            <div className="flex-1">
                              <h5 className="mb-0 text-1000">
                                {item.name || '-'}
                              </h5>
                              <p
                                className="fs-12 mb-0 row text-700"
                                style={{ justifyContent: 'space-between' }}
                              >
                                <span className="fw-medium text-600 col-4">
                                  {item.email}
                                </span>
                                <span className="fw-medium text-600 col-4">
                                  &nbsp;
                                  <FeatherIcon icon="link" size={12} />
                                  &nbsp;Id:&nbsp;
                                  {item.id}
                                </span>
                                <span
                                  className="fw-medium text-600 col-4 d-flex"
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon icon="calendar" size={14} />
                                  &nbsp;
                                  <span>
                                    Created Date:&nbsp;
                                    {moment(item.created_at).format(
                                      'yyyy-MM-DD'
                                    )}
                                  </span>
                                </span>
                              </p>
                            </div>
                          </span>
                        ) : item.source === 'vans' ? (
                          <span
                            className="py-2 d-flex gap-2 "
                            style={{ margin: '0 0 -10px 10px' }}
                          >
                            <div className="d-flex align-center">
                              <FeatherIcon icon="truck" size={20} />
                            </div>
                            <div className="flex-1">
                              <h5 className="mb-0 text-1000">
                                {item.name || '-'}
                              </h5>
                              <p
                                className="fs-12 mb-0 row text-700"
                                style={{ justifyContent: 'space-between' }}
                              >
                                <span className="fw-medium text-600 col-4">
                                  Make:&nbsp;{item.booking_reference}
                                </span>
                                <span className="fw-medium text-600 col-4">
                                  &nbsp;
                                  <FeatherIcon icon="link" size={12} />
                                  &nbsp;Id:&nbsp;
                                  {item.id}
                                </span>
                                <span
                                  className="fw-medium text-600 col-4 d-flex"
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon icon="calendar" size={14} />
                                  &nbsp;
                                  <span>
                                    Created Date:&nbsp;
                                    {moment(item.created_at).format(
                                      'yyyy-MM-DD'
                                    )}
                                  </span>
                                </span>
                              </p>
                            </div>
                          </span>
                        ) : item.source === 'franchises' ? (
                          <span
                            className="py-2 d-flex gap-2 "
                            style={{ margin: '0 0 -10px 10px' }}
                          >
                            <div className="d-flex align-center">
                              <FeatherIcon icon="file-text" size={20} />
                            </div>
                            <div className="flex-1">
                              <h5 className="mb-0 text-1000">
                                {item.name || '-'} ({item.short_name})
                              </h5>
                              <p
                                className="fs-12 mb-0 row text-700"
                                style={{ justifyContent: 'space-between' }}
                              >
                                <span className="fw-medium text-600 col-4">
                                  {item.email}
                                </span>
                                <span className="fw-medium text-600 col-4">
                                  <FeatherIcon icon="phone" size={12} />
                                  &nbsp; Phone:&nbsp;
                                  {item.phone}
                                </span>

                                <span className="fw-medium text-600 col-4">
                                  <FeatherIcon icon="calendar" size={14} />
                                  <span>
                                    Created Date:&nbsp;
                                    {moment(item.created_at).format(
                                      'yyyy-MM-DD'
                                    )}
                                  </span>
                                </span>
                              </p>
                            </div>
                          </span>
                        ) : item.source === 'depots' ? (
                          <span
                            className="py-2 d-flex gap-2 "
                            style={{ margin: '0 0 -10px 10px' }}
                          >
                            <div className="d-flex align-center">
                              <FeatherIcon icon="file-text" size={20} />
                            </div>
                            <div className="flex-1">
                              <h5 className="mb-0 text-1000">
                                {item.name || '-'}
                              </h5>
                              <p
                                className="fs-12 mb-0 row text-700"
                                style={{ justifyContent: 'space-between' }}
                              >
                                <span className="fw-medium text-600 col-6">
                                  {item.email}
                                </span>
                                <span className="fw-medium text-600 col-6">
                                  <FeatherIcon icon="phone" size={12} />
                                  &nbsp; Phone:&nbsp;
                                  {item.phone}
                                </span>
                                <span className="fw-medium text-600 col-6">
                                  Short Name:&nbsp;
                                  {item.short_name}
                                </span>
                                <span
                                  className="fw-medium text-600 col-6 d-flex"
                                  style={{ alignItems: 'center' }}
                                >
                                  <FeatherIcon icon="calendar" size={14} />
                                  &nbsp;
                                  <span>
                                    Created Date:&nbsp;
                                    {moment(item.created_at).format(
                                      'yyyy-MM-DD'
                                    )}
                                  </span>
                                </span>
                              </p>
                            </div>
                          </span>
                        ) : (
                          <div
                            className="d-flex align-items-center fw-normal gap-1 text-1000"
                            style={{ paddingLeft: '25px' }}
                          >
                            {item.id}
                          </div>
                        )}
                      </Dropdown.Item>
                    </>
                  ))}
                </div>
              )
            )}

          {/* {Children.map(children, child =>
            cloneElement(child as ReactElement, {
              searchValue: searchInputValue
            })
          )} */}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default DropdownSearchBox;
