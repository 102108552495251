import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface MainLayoutContextInterface {
  contentClass: string;
  setContentClass: Dispatch<SetStateAction<string>>;
  footerClass: string;
  setFooterClass: Dispatch<SetStateAction<string>>;
}

export const MainLayoutContext = createContext(
  {} as MainLayoutContextInterface
);

const MainLayoutProvider = ({ children }: PropsWithChildren) => {
  const history = useNavigate();

  const [contentClass, setContentClass] = useState('');
  const [footerClass, setFooterClass] = useState('');
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      history('/auth/login');
    }
  }, []);
  const checkCookieExpiration = (): void => {
    const cookieValue: string | undefined = Cookies.get('token');

    if (cookieValue) {
      const cookieExpiration: string | undefined = Cookies.get('token');
      const currentTime: number = Date.now();

      if (cookieExpiration) {
        const expirationTime: number = new Date(cookieExpiration).getTime();

        if (currentTime > expirationTime) {
          logout();
        }
      }
    }
  };
  const logout = (): void => {
    console.log('Logging out...');
    Cookies.remove('token');
    history('/auth/login');
    // Implement your logout functionality here
    // Redirect to the login page or perform any other necessary actions
  };
  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(() => {
      checkCookieExpiration();
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);
  return (
    <MainLayoutContext.Provider
      value={{ contentClass, setContentClass, footerClass, setFooterClass }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

export const useMainLayoutContext = () => useContext(MainLayoutContext);

export default MainLayoutProvider;
