import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUsersList, getUsersDetail } from './usersApi';

interface UserState {
  list: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
  detail: {
    isLoading: boolean;
    isError: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
  };
}

const initialState: UserState = {
  list: {
    isLoading: false,
    data: {
      data: [],
      meta: {}
    },
    isError: false
  },
  detail: {
    isLoading: false,
    data: {},
    isError: false
  }
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUsersList.pending, state => {
      state.list.isLoading = true;
      state.list.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getUsersList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
        state.list.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getUsersList.rejected, state => {
      state.list.isLoading = false;
      state.list.isError = true;
    });
    builder.addCase(getUsersDetail.pending, state => {
      state.detail.isLoading = true;
      state.detail.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getUsersDetail.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.detail.data = action.payload;
      }
    );
    builder.addCase(getUsersDetail.rejected, state => {
      state.detail.isLoading = false;
      state.detail.isError = true;
    });
  }
});

export default usersSlice.reducer;
