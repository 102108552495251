export const depotOpenHours = [
  { id: '08:00', name: '08:00' },
  { id: '08:30', name: '08:30' },
  { id: '09:00', name: '09:00' },
  { id: '09:30', name: '09:30' },
  { id: '10:00', name: '10:00' },
  { id: '10:30', name: '10:30' },
  { id: '11:00', name: '11:00' },
  { id: '11:30', name: '11:30' },
  { id: '12:00', name: '12:00' },
  { id: '12:30', name: '12:30' },
  { id: '13:00', name: '13:00' },
  { id: '13:30', name: '13:30' },
  { id: '14:00', name: '14:00' },
  { id: '14:30', name: '14:30' },
  { id: '15:00', name: '15:00' },
  { id: '15:30', name: '15:30' },
  { id: '16:00', name: '16:00' },
  { id: '16:30', name: '16:30' },
  { id: '17:00', name: '17:00' },
  { id: '17:30', name: '17:30' },
  { id: '18:00', name: '18:00' },
  { id: '18:30', name: '18:30' },
  { id: '19:00', name: '19:00' },
  { id: '19:30', name: '19:30' },
  { id: '20:00', name: '20:00' },
  { id: '00:00', name: 'Closed' }
];
export const depotCloseHours = [
  { id: '01:00', name: '01:00' },
  { id: '01:30', name: '01:30' },
  { id: '02:00', name: '02:00' },
  { id: '02:30', name: '02:30' },
  { id: '03:00', name: '03:00' },
  { id: '03:30', name: '03:30' },
  { id: '04:00', name: '04:00' },
  { id: '04:30', name: '04:30' },
  { id: '05:00', name: '05:00' },
  { id: '05:30', name: '05:30' },
  { id: '06:00', name: '06:00' },
  { id: '06:30', name: '06:30' },
  { id: '07:00', name: '07:00' },
  { id: '07:30', name: '07:30' },
  { id: '08:00', name: '08:00' },
  { id: '08:30', name: '08:30' },
  { id: '09:00', name: '09:00' },
  { id: '09:30', name: '09:30' },
  { id: '10:00', name: '10:00' },
  { id: '10:30', name: '10:30' },
  { id: '11:00', name: '11:00' },
  { id: '11:30', name: '11:30' },
  { id: '12:00', name: '12:00' },
  { id: '12:30', name: '12:30' },
  { id: '13:00', name: '13:00' },
  { id: '13:30', name: '13:30' },
  { id: '14:00', name: '14:00' },
  { id: '14:30', name: '14:30' },
  { id: '15:00', name: '15:00' },
  { id: '15:30', name: '15:30' },
  { id: '16:00', name: '16:00' },
  { id: '16:30', name: '16:30' },
  { id: '17:00', name: '17:00' },
  { id: '17:30', name: '17:30' },
  { id: '18:00', name: '18:00' },
  { id: '18:30', name: '18:30' },
  { id: '19:00', name: '19:00' },
  { id: '19:30', name: '19:30' },
  { id: '20:00', name: '20:00' },
  { id: '21:00', name: '21:00' },
  { id: '21:30', name: '21:30' },
  { id: '22:00', name: '22:00' },
  { id: '22:30', name: '22:30' },
  { id: '23:00', name: '23:00' },
  { id: '23:30', name: '23:30' },
  { id: '24:00', name: '24:00' },
  { id: '00:00', name: 'Closed' }
];

export const weeks = [
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
  { id: 7, name: 'Sunday' }
];
