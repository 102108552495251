import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <AppProvider>
      <SettingsPanelProvider>
        <BreakpointsProvider>
          <SnackbarProvider autoHideDuration={3000}>
            <RouterProvider router={router} />
          </SnackbarProvider>
        </BreakpointsProvider>
      </SettingsPanelProvider>
    </AppProvider>
  </Provider>
);
