import React from 'react';
import Button from 'components/base/Button';
import { Card, Table, Form, Dropdown } from 'react-bootstrap';
import {
  faEllipsisV,
  faTag,
  faTrashAlt,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const OrderEdit = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <div>
      <div className="d-flex align-item-center justify-content-between mb-4">
        <h3>#Edit Order</h3>
        <Button variant="primary">Create Order</Button>
      </div>
      <Card className="p-2">
        <div className="text-end mb-3">
          <Button variant="primary">Dismiss</Button>{' '}
          <Button variant="primary">Update</Button>
        </div>
        <div className="orderTable">
          <Table responsive>
            <thead>
              <tr>
                <th>Items</th>
                <th>Price Per Unit (£)</th>
                <th>Quantity</th>
                <th align="center">Amount</th>
                <th align="right"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>POD </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>£202.62</td>
                <td align="right" className="toggelBtn">
                  <Dropdown>
                    <Dropdown.Toggle variant="phoenix-primary">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-4">
                        Separated link
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <td>POD </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>£202.62</td>
                <td align="right" className="toggelBtn">
                  <Dropdown>
                    <Dropdown.Toggle variant="phoenix-primary">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-4">
                        Separated link
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <td>POD </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>£202.62</td>
                <td align="right" className="toggelBtn">
                  <Dropdown>
                    <Dropdown.Toggle variant="phoenix-primary">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-4">
                        Separated link
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <td>POD </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>£202.62</td>
                <td align="right" className="toggelBtn">
                  <Dropdown>
                    <Dropdown.Toggle variant="phoenix-primary">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-4">
                        Separated link
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <td>POD </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>£202.62</td>
                <td align="right" className="toggelBtn">
                  <Dropdown>
                    <Dropdown.Toggle variant="phoenix-primary">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-4">
                        Separated link
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              <tr>
                <td>POD </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>
                  {' '}
                  <Form.Control type="text" placeholder="Normal text" />
                </td>
                <td>£202.62</td>
                <td align="right" className="toggelBtn">
                  <Dropdown>
                    <Dropdown.Toggle variant="phoenix-primary">
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action-4">
                        Separated link
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
              <tr className="acttad">
                <td>
                  <div className="discount">
                    <figure>
                      <FontAwesomeIcon icon={faTag} />
                    </figure>
                    <span>
                      <strong>Discount50</strong>
                    </span>
                    <span className="d-block">Discount50</span>
                    <span className="d-block">applied</span>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td align="right">
                  33.33% <FontAwesomeIcon icon={faTrashAlt} />
                </td>
              </tr>
              <tr className="acttad">
                <td>
                  <div className="discount">
                    <figure>
                      <FontAwesomeIcon icon={faTag} />
                    </figure>
                    <span>
                      <strong>Discount50</strong>
                    </span>
                    <span className="d-block">Discount50</span>
                    <span className="d-block">applied</span>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td align="right">
                  25% <FontAwesomeIcon icon={faTrashAlt} />
                </td>
              </tr>
              <tr className="lightbg">
                <td colSpan={5}>
                  <Button variant="outline-secondary" className="me-1">
                    {' '}
                    <FontAwesomeIcon icon={faPlusCircle} /> Add Recurring Addon{' '}
                  </Button>
                  <Button variant="outline-secondary">
                    <FontAwesomeIcon icon={faPlusCircle} /> Add Charge{' '}
                  </Button>
                </td>
              </tr>
              <tr className="lightbg">
                <td colSpan={5}>
                  <Button variant="outline-secondary" className="me-1">
                    {' '}
                    <FontAwesomeIcon icon={faPlusCircle} /> Add Coupon Code{' '}
                  </Button>
                  <Button variant="outline-secondary">
                    {' '}
                    <FontAwesomeIcon icon={faPlusCircle} /> Add manual discount{' '}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Card>
    </div>
  );
};
