/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CustomPagination from 'components/base/CustomPagination';
import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';

interface Profiles {
  id: string;
  name: string;
  description: string;
  status: number;
}

interface ProfilesTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}

export const profilesTableColumns: ColumnDef<Profiles>[] = [
  {
    accessorKey: 'name',
    header: 'Profile NAME',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return <>{name}</>;
    },
    meta: {
      headerProps: { style: { width: '30%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'description',
    header: 'PROFILE DESCRIPTION',
    cell: ({ row: { original } }) => {
      const { description } = original;
      return <>{description}</>;
    },
    meta: {
      headerProps: { style: { width: '40%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    id: 'status',
    // accessorFn: ({ payment_status }) => payment_status.label,
    header: 'PROFILE ACTIVE',
    cell: ({ row: { original } }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status } = original;
      if (status == 1) {
        return (
          <Badge
            bg={'success'}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                icon={'feather-check'}
                size={12.8}
                className="ms-1"
              />
            }
          >
            Active
          </Badge>
        );
      } else {
        return (
          <Badge
            bg={'danger'}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                icon={'feather-check'}
                size={12.8}
                className="ms-1"
              />
            }
          >
            InActive
          </Badge>
        );
      }
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3' }
    }
  }
];

const ProfilesTable: React.FC<ProfilesTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default ProfilesTable;
