/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import CustomPagination from 'components/base/CustomPagination';
import moment from 'moment';
interface Deals {
  deal_status: string;
  user_data: {
    first_name: string;
    last_name: string;
  };
  closed_at: string;
  franchise_data: {
    name: string;
  };
  agent_data: {
    first_name: string;
  };
  amount: string;
  created_at: string;
}
interface DealsTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}
export const dealsTableColumns: ColumnDef<Deals>[] = [
  {
    accessorKey: 'user_data',
    header: 'DEAL NAME',
    cell: ({ row: { original } }) => {
      const { user_data } = original;
      return (
        <>
          {user_data.first_name}&nbsp;{user_data.last_name}
        </>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'deal_status',
    header: 'DEAL STAGE',
    cell: ({ row: { original } }) => {
      const { deal_status } = original;
      return <>{deal_status}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'closed_at',
    header: 'CLOSING DATE',
    cell: ({ row: { original } }) => {
      const { closed_at } = original;
      return <>{closed_at || '-'}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'franchise_data',
    header: 'FRANCHISE',
    cell: ({ row: { original } }) => {
      const { franchise_data } = original;
      return (
        <>
          {franchise_data && franchise_data.name ? franchise_data.name : ' - '}
        </>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'agent',
    header: 'AGENT',
    cell: ({ row: { original } }) => {
      const { agent_data } = original;
      return (
        <>
          {agent_data && agent_data.first_name ? agent_data.first_name : ' - '}
        </>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'amount',
    header: 'AMOUNT',
    cell: ({ row: { original } }) => {
      const { amount } = original;
      return <>{amount}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'created_at',
    header: 'CREATE DATE',
    cell: ({ row: { original } }) => {
      const { created_at } = original;
      return <>{moment(created_at).format('DD-MM-YYYY')}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  }
];

const DealsTable: React.FC<DealsTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default DealsTable;
