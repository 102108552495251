import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/Table/promotionsFilter';
import { makeRequest } from 'helpers/apiCall';

export const getPromotionsList = createAsyncThunk(
  'promotions/list',
  async (queryParams: QueryParams) => {
    try {
      const trimmedOffset = queryParams.offset
        .split(',')
        .map((element: string) => element.trim());
      const response = await makeRequest(
        'GET',
        `promotion?page=${queryParams.pageNumber}&per_page=${
          queryParams.pageSize
        }&sort_by=${queryParams.sortField}${
          queryParams.offset.length
            ? `&offset=${JSON.stringify(trimmedOffset)}`
            : ''
        }&sort_dir=${queryParams.sortOrder}&search_keyword=${
          queryParams.filter.search
        } &search_franchise=${queryParams.filter.search_franchise}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPromotionsDetail = createAsyncThunk(
  'promotions/detail',
  async (id: string) => {
    try {
      const response = await makeRequest('GET', `promotion/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
