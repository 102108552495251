/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CustomPagination from 'components/base/CustomPagination';

interface Customers {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  franchise: string;
  agent: string;
  agent_id: string;
  id: string;
}

interface CustomersTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}

export const customersTableColumns: ColumnDef<Customers>[] = [
  {
    accessorKey: 'first_name',
    header: 'CUSTOMER NAME',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original?.first_name || '-'}&nbsp;{original?.last_name || ''}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '27%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'phone',
    header: 'PHONE',
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return <>{phone}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'email',
    header: 'EMAIL',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return <>{email}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'franchise',
    header: '	FRANCHISE',
    cell: ({ row: { original } }) => {
      return <>{original?.franchise || '-'}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'agent',
    header: '	AGENT',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original?.agent_id === original?.id
            ? 'Online'
            : original?.agent || '-'}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  }
];

const CustomersTable: React.FC<CustomersTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default CustomersTable;
