/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
// import { currencyFormat } from 'helpers/utils';
import CustomPagination from 'components/base/CustomPagination';
// import Avatar from 'components/base/Avatar';
// import { Order } from 'data/e-commerce/orders';
import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';
import UsersDropdownItems from 'components/common/UsersDropdownItems';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';

interface Country {
  id: string;
  first_name: string;
  profile: string;
  franchise: string;
  email: string;
  status: number;
}
interface CountryTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}
export const countryTableColumns: ColumnDef<Country>[] = [
  {
    accessorKey: 'id',
    header: 'USER ID',
    cell: ({ row: { original } }) => {
      const { id } = original;
      return <>{id}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'first_name',
    header: 'USER NAME',
    cell: ({ row: { original } }) => {
      const { first_name } = original;
      return <>{first_name}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'roles',
    header: 'PROFILE',
    cell: ({ row: { original } }) => {
      const { profile } = original;
      return <>{profile}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'franchises',
    header: 'FRANCHISES',
    cell: ({ row: { original } }) => {
      const { franchise } = original;
      return <>{franchise}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'email',
    header: 'EMAIL',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return <>{email}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    id: 'status',
    // accessorFn: ({ payment_status }) => payment_status.label,
    header: 'Status',
    cell: ({ row: { original } }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status } = original;
      if (status == 1) {
        return (
          <Badge
            bg={'success'}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                icon={'feather-check'}
                size={12.8}
                className="ms-1"
              />
            }
          >
            Active
          </Badge>
        );
      } else {
        return (
          <Badge
            bg={'danger'}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                icon={'feather-check'}
                size={12.8}
                className="ms-1"
              />
            }
          >
            Inactive
          </Badge>
        );
      }
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3' }
    }
  },
  {
    id: 'action',
    cell: ({ row: { original } }) => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <UsersDropdownItems setUrl={{ url: `${original.id}` }} />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '7%' } },
      cellProps: { className: 'text-end' }
    }
  }
];

const CountryTable: React.FC<CountryTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default CountryTable;

//   {
//     accessorKey: 'total',
//     header: 'Total',
//     cell: ({ row: { original } }) => currencyFormat(original.total),
//     meta: {
//       headerProps: { style: { width: '6%' }, className: 'text-end' },
//       cellProps: { className: 'text-end fw-semi-bold text-1000' }
//     }
//   },
//   {
//     id: 'customer',
//     accessorFn: ({ customer }) => customer.name,
//     header: 'Customer',
//     cell: ({ row: { original } }) => {
//       const { name, avatar } = original.customer;
//       return (
//         <Link
//           to="/apps/e-commerce/admin/customer-details"
//           className="d-flex align-items-center"
//         >
//           <Avatar src={avatar} size="m" />
//           <p className="mb-0 ms-3 text-1100 fw-bold">{name}</p>
//         </Link>
//       );
//     },
//     meta: {
//       headerProps: {
//         style: { width: '28%', minWidth: 250 },
//         className: 'ps-8'
//       },
//       cellProps: { className: 'ps-8 py-0 white-space-nowrap' }
//     }
//   },
//   {
//     id: 'payment_status',
//     accessorFn: ({ payment_status }) => payment_status.label,
//     header: 'Payment status',
//     cell: ({ row: { original } }) => {
//       const { payment_status } = original;
//       return (
//         <Badge
//           bg={payment_status.type}
//           variant="phoenix"
//           iconPosition="end"
//           className="fs-10"
//           icon={
//             <FeatherIcon
//               icon={payment_status.icon}
//               size={12.8}
//               className="ms-1"
//             />
//           }
//         >
//           {payment_status.label}
//         </Badge>
//       );
//     },
//     meta: {
//       headerProps: { style: { width: '10%' }, className: 'pe-3' }
//     }
//   },
//   {
//     id: 'fulfilment_status',
//     accessorFn: ({ fulfilment_status }) => fulfilment_status.label,
//     header: 'Fulfilment status',
//     cell: ({ row: { original } }) => {
//       const { fulfilment_status } = original;
//       return (
//         <Badge
//           bg={fulfilment_status.type}
//           variant="phoenix"
//           iconPosition="end"
//           className="fs-10"
//           icon={
//             <FeatherIcon
//               icon={fulfilment_status.icon}
//               size={12.8}
//               className="ms-1"
//             />
//           }
//         >
//           {fulfilment_status.label}
//         </Badge>
//       );
//     },
//     meta: {
//       headerProps: { style: { width: '12%', minWidth: 200 }, className: 'pe-3' }
//     }
//   },
//   {
//     accessorKey: 'delivery_type',
//     header: 'Delivery type',
//     meta: {
//       headerProps: { style: { width: '30%' } },
//       cellProps: { className: 'text-900 fs-9' }
//     }
//   },
//   {
//     accessorKey: 'date',
//     header: 'Date',
//     meta: {
//       headerProps: { className: 'text-end' },
//       cellProps: { className: 'text-700 fs-9 ps-4 text-end white-space-nowrap' }
//     }
//   }
