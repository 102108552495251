import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCountriesList, getCountriesDetail } from './countriesApi';

interface CountriesState {
  list: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
  detail: {
    isLoading: boolean;
    isError: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
  };
}

const initialState: CountriesState = {
  list: {
    isLoading: false,
    data: {
      data: [],
      meta: {}
    },
    isError: false
  },
  detail: {
    isLoading: false,
    data: {},
    isError: false
  }
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCountriesList.pending, state => {
      state.list.isLoading = true;
      state.list.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getCountriesList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
        state.list.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getCountriesList.rejected, state => {
      state.list.isLoading = false;
      state.list.isError = true;
    });
    builder.addCase(getCountriesDetail.pending, state => {
      state.detail.isLoading = true;
      state.detail.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getCountriesDetail.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.detail.data = action.payload;
      }
    );
    builder.addCase(getCountriesDetail.rejected, state => {
      state.detail.isLoading = false;
      state.detail.isError = true;
    });
  }
});

export default countriesSlice.reducer;
