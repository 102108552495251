import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/contactsFilter';
import { makeRequest } from 'helpers/apiCall';

export const getContactsList = createAsyncThunk(
  'contacts/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `contact?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}&search_agent=${queryParams.filter.search_agent}&search_view=${queryParams.filter.search_view}&from_date=${queryParams.filter.from_date}&to_date=${queryParams.filter.to_date}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
