import React from 'react';
import { CardBody, Dropdown } from 'react-bootstrap';
import { faEllipsisH, faTruckField } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import Button from 'components/base/Button';
import { OrderList } from './order-list';
export const OrderDetailInfo = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <Card className="mb-3">
      <CardBody>
        <div className="orderInfoHead">
          <div>
            <span className="me-2">
              <Button variant="soft-success">
                {' '}
                <FontAwesomeIcon icon={faTruckField} />
                Fulfilled (8){' '}
              </Button>
            </span>
            <span>#1251-F1</span>
          </div>
          <div className="toggelBtn">
            <Dropdown>
              <Dropdown.Toggle variant="phoenix-primary">
                <FontAwesomeIcon icon={faEllipsisH} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Card>
          <div className="p-3 OderLocation">
            <ul>
              <li>
                Location <span>easyStorage</span>
              </li>
              <li>
                Fulfilled <span>16 January 2024</span>
              </li>
              <li>
                DPD Local{' '}
                <span>
                  <a href="">6891821141</a>
                </span>
              </li>
            </ul>
          </div>
          <OrderList />
          <OrderList />
          <OrderList />
          <OrderList />
          <OrderList />
        </Card>
      </CardBody>
    </Card>
  );
};
