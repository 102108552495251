/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface CountryPhoneNumberProps {
  handleCountryPhone?: (data: any) => void;
  countryPhoneValue: string;
  formik: any; // Replace YourFormikType with the actual type of your formik object
  fieldKey: string;
  type?: string;
  functionData?: number;
}

export const CountryPhoneNumber: React.FC<CountryPhoneNumberProps> = ({
  handleCountryPhone,
  countryPhoneValue,
  formik,
  fieldKey,
  type,
  functionData
}) => {
  return (
    <PhoneInput
      inputClass="form-control "
      country={'gb'} // Replace with the desired default country code
      inputProps={{
        style: { width: '100%' }
      }}
      placeholder="Enter phone number"
      value={countryPhoneValue}
      onChange={e => {
        if (type === 'on_page') {
          const data = {
            e,
            index: functionData,
            field: fieldKey
          };
          if (handleCountryPhone && typeof handleCountryPhone === 'function') {
            // Now TypeScript knows handleCountryPhone is a function
            handleCountryPhone(data ?? {});
          }
          return;
        }
        formik.setFieldValue(fieldKey, e);
      }}
    />
  );
};

export default CountryPhoneNumber;
