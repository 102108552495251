/* eslint-disable @typescript-eslint/no-explicit-any */
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useMemo, useState } from 'react';
import FranchisesTable, {
  franchisesTableColumns
} from '../../../../components/tables/FranchisesTable';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getFranchisesList } from '../../../../redux/features/Franchises/franchisesApi';

const FranchisesList = () => {
  const dispatch = useAppDispatch();
  const franchises = useSelector(
    (state: RootState) => state.franchises.list.data
  );
  const isLoading = useSelector(
    (state: RootState) => state.franchises.list.isLoading
  );
  const location = useLocation();

  const [filter, setFilter] = useState({
    search: ''
  });

  const table = useAdvanceTable({
    data: franchises?.data || [],
    columns: franchisesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(franchises?.meta?.total),
      current_page: Number(franchises?.meta?.current_page),
      isLoading: isLoading
    };
  }, [location, franchises?.meta, isLoading]);

  const handleFranchiseList = async (): Promise<void> => {
    dispatch(
      getFranchisesList({
        pageNumber: paginationProps?.page,
        pageSize: paginationProps?.dataPerPage,
        sortField: '',
        sortOrder: '',
        filter: filter
      })
    );
  };
  useEffect(() => {
    handleFranchiseList();
  }, [location, filter]);
  const handleFilter = (key: any, value: any) => {
    setFilter({ ...filter, [key]: value });
  };
  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Franchises</h2>
        {/* <FilterTab tabItems={tabItems} className="mb-2" /> */}

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search orders"
                onChange={e => handleFilter('search', e.target.value)}
              />
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  New Franchise
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <FranchisesTable paginationProps={paginationProps} />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default FranchisesList;
