import React from 'react';
import Button from 'components/base/Button';
import { Dropdown, Modal, Form } from 'react-bootstrap';
import Unicon from 'components/base/Unicon';
import { UilPen } from '@iconscout/react-unicons';
import { Card } from 'react-bootstrap';

export const OrderAction = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="actionButton">
      <ul>
        {/* <li>
          <Button variant="phoenix-secondary"> Refund </Button>
        </li>
        <li>
          <Button variant="phoenix-secondary"> Return </Button>
        </li> */}
        <li>
          <Button variant="phoenix-secondary"> Edit </Button>
        </li>
        <li>
          <Dropdown>
            <Dropdown.Toggle variant="phoenix-secondary">
              More actions
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        <li>
          <Button variant="warning" onClick={() => setModalShow(true)}>
            {' '}
            Storage Details{' '}
          </Button>
        </li>
      </ul>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Storage Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <div className="storageSection">
                <div>Pod Number</div>
                <div>
                  <Form.Control type="text" placeholder="Normal text" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="storageSection">
                <div>Seal Number 1: </div>
                <div>
                  <Form.Control type="text" placeholder="Normal text" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="storageSection">
                <div>Seal Number 2 :</div>
                <div>
                  <Form.Control type="text" placeholder="Normal text" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 mb-5">
            <div className="col-md-6">
              <Card className="p-3">
                <div className="depothead">
                  <h5>Depot</h5>
                  <span>
                    <Unicon icon={UilPen} />
                  </span>
                </div>
                <div>
                  <strong>Name :</strong> Codogon Tate (Action)
                </div>
                <div>
                  <strong>Address:</strong> 239 Acton Lane
                </div>
                <div>
                  London
                  <br />
                  NW10 7NP
                </div>
              </Card>
            </div>
          </div>
          <div className="text-center">sddsfsdff</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalShow(false)}
            variant="phoenix-secondary"
            className="me-1"
          >
            Cancel
          </Button>
          <Button
            onClick={() => setModalShow(false)}
            variant="phoenix-secondary"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
