import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import QuotesTable, {
  quotesTableColumns
} from '../../../../components/tables/QuotesTable';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getQuotesList } from '../../../../redux/features/Settings/Quotes/quotesApi';

const QuotesList = () => {
  const dispatch = useAppDispatch();
  const quotes = useSelector((state: RootState) => state.quotes.list.data);
  const isLoading = useSelector(
    (state: RootState) => state.quotes.list.isLoading
  );
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState({
    list: [],
    meta: { total: null, current_page: 1 }
  });

  const table = useAdvanceTable({
    data: quotes?.data || [],
    columns: quotesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(quotes?.meta?.total),
      current_page: Number(quotes?.meta?.current_page),
      isLoading: isLoading
    };
  }, [location, quotes?.meta, isLoading]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  const handleQuotesList = async (): Promise<void> => {
    dispatch(
      getQuotesList({
        pageNumber: paginationProps?.page,
        pageSize: paginationProps?.dataPerPage,
        sortField: '',
        sortOrder: '',
        filter: {
          search: ''
        }
      })
    );
  };
  useEffect(() => {
    handleQuotesList();
  }, [location]);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Quotes</h2>
        {/* <FilterTab tabItems={tabItems} className="mb-2" /> */}

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <QuotesTable paginationProps={paginationProps} />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default QuotesList;
