import React from 'react';
import imageDemo from '../../assets/img/prod.jpg';

export const OrderList = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <div className="orderList">
      <div className="d-flex">
        <div className="orderImage">
          <img src={imageDemo} alt="" />
        </div>
        <div className="orderDesc">
          <a href="">
            Medium easyStorage Cardboard Heavy Duty Moving Box 450x450x250mm
          </a>
          <span>SKU:CTNEAS01</span>
        </div>
      </div>
      <div>£4.25 x 6</div>
      <div>£25.50</div>
    </div>
  );
};
