import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/countriesFilter';
import { makeRequest } from 'helpers/apiCall';

export const getCountriesList = createAsyncThunk(
  'countries/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `country?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCountriesDetail = createAsyncThunk(
  'countries/detail',
  async (id: string) => {
    try {
      const response = await makeRequest('GET', `country/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
