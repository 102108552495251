import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { usersSlice } from './features/Settings/Users/usersSlices';
import { depotsSlice } from './features/Settings/Depots/depotsSlices';
import { productsSlice } from './features/Settings/Products/productsSlices';
import { promotionsSlice } from './features/Settings/Promotions/promotionsSlices';
import { countriesSlice } from './features/Countries/countriesSlices';
import { franchisesSlice } from './features/Franchises/franchisesSlices';
import { profilesSlice } from './features/Profiles/profilesSlices';
import { timelineSlice } from './features/Settings/Timeline/timelineSlices';
import { commonSlice } from './features/Common/commonSlice';
import { customersSlice } from './features/Settings/Customers/customersSlices';
import { contactsSlice } from './features/Settings/Contacts/contactsSlices';
import { quotesSlice } from './features/Settings/Quotes/quotesSlices';
import { ordersSlice } from './features/Orders/ordersSlices';
import { dealsSlice } from './features/Deals/dealsSlices';
import { agentsSlice } from './features/Agents/agentsSlices';
import { tasksSlice } from './features/Tasks/tasksSlices';

// Define RootState type using ReturnType utility
export type RootState = ReturnType<typeof store.getState>;

// Configure the store with the usersSlice reducer
export const store = configureStore({
  reducer: {
    users: usersSlice.reducer,
    depots: depotsSlice.reducer,
    products: productsSlice.reducer,
    promotion: promotionsSlice.reducer,
    countries: countriesSlice.reducer,
    franchises: franchisesSlice.reducer,
    profiles: profilesSlice.reducer,
    timeline: timelineSlice.reducer,
    common: commonSlice.reducer,
    customers: customersSlice.reducer,
    contacts: contactsSlice.reducer,
    quotes: quotesSlice.reducer,
    orders: ordersSlice.reducer,
    deals: dealsSlice.reducer,
    agents: agentsSlice.reducer,
    tasks: tasksSlice.reducer
  }
});

// Export a typed version of useDispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Define the AppDispatch type
export type AppDispatch = typeof store.dispatch;

// Export a typed useSelector hook
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
