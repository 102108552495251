/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import CustomPagination from 'components/base/CustomPagination';
import moment from 'moment';

interface Quotes {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deal_data: any;
  email: string;
  phone: string;
  franchise_name: string;
  source: string;
  amount: string;
  created_at: string;
}

interface QuotesTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}

export const quotesTableColumns: ColumnDef<Quotes>[] = [
  {
    accessorKey: 'deal_data',
    header: 'NAME',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original?.deal_data?.contact?.user?.first_name}{' '}
          {original?.deal_data?.contact?.user?.last_name}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row: { original } }) => {
      return <>{original.email}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    cell: ({ row: { original } }) => {
      return <>{original.phone}</>;
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'franchise_name',
    header: 'Franchise',
    cell: ({ row: { original } }) => {
      return <>{original.franchise_name}</>;
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'source',
    header: 'Source',
    cell: ({ row: { original } }) => {
      let sourceName = '';
      if (original?.source === 'Online') {
        sourceName = 'Online';
      } else if (original?.source === 'Draft') {
        sourceName = 'V2';
      } else if (original?.source === 'Quote') {
        sourceName = 'V2';
      } else if (original?.source === 'PostHog') {
        sourceName = 'PostHog';
      }
      return <>{sourceName}</>;
    },
    meta: {
      headerProps: { style: { width: '12%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'source',
    header: 'Type',
    cell: ({ row: { original } }) => {
      let sourceType = '';
      if (original?.source === 'Online') {
        sourceType = 'Quote';
      } else if (original?.source === 'Draft') {
        sourceType = 'Draft';
      } else if (original?.source === 'Quote') {
        sourceType = 'Quote';
      } else if (original?.source === 'PostHog') {
        sourceType = 'Quote';
      }
      return <>{sourceType}</>;
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'deal_data',
    header: 'Status',
    cell: ({ row: { original } }) => {
      let status = '';
      if (original?.deal_data?.deal_status === 'Close-Won') {
        status = 'Closed';
      } else if (original?.deal_data?.deal_status === 'Close-Lost') {
        status = 'Lost';
      } else if (
        original?.deal_data?.deal_status === 'Transit' ||
        original?.deal_data?.deal_status === 'Pipeline'
      ) {
        status = 'Open';
      } else if (original?.deal_data?.deal_status === 'Open') {
        status = 'Open';
      }
      return <>{status}</>;
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    cell: ({ row: { original } }) => {
      return <>£{original?.amount}</>;
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'created_at',
    header: 'CREATE DATE',
    cell: ({ row: { original } }) => {
      return <>{moment(original.created_at).format('DD-MM-YYYY')}</>;
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  }
];

const QuotesTable: React.FC<QuotesTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default QuotesTable;
