/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getActiveCountry,
  getAgentsList,
  getCountryFranchiseList,
  getFranchiseList,
  getGlobalSearch,
  getPermissions,
  getProfileList
} from './commonApi';

interface CountriesState {
  franchiselist: {
    isLoading: boolean;
    data: any;
    isError: boolean;
  };
  agentlist: {
    isLoading: boolean;
    data: any;
    isError: boolean;
  };
  countrylist: {
    isLoading: boolean;
    data: any;
    isError: boolean;
  };
  profilelist: {
    isLoading: boolean;
    data: any;
    isError: boolean;
  };
  countryFranchiselist: {
    isLoading: boolean;
    data: any;
    isError: boolean;
  };
  permission: {
    isLoading: boolean;
    data: any;
    isError: boolean;
  };
  globalSearch: {
    isLoading: boolean;
    data: any;
    isError: boolean;
  };
}

const initialState: CountriesState = {
  franchiselist: {
    isLoading: false,
    data: {
      data: []
    },
    isError: false
  },
  agentlist: {
    isLoading: false,
    data: {
      data: []
    },
    isError: false
  },
  countrylist: {
    isLoading: false,
    data: {
      data: []
    },
    isError: false
  },
  profilelist: {
    isLoading: false,
    data: {
      data: []
    },
    isError: false
  },
  countryFranchiselist: {
    isLoading: false,
    data: {
      data: []
    },
    isError: false
  },
  permission: {
    isLoading: false,
    data: [],
    isError: false
  },
  globalSearch: {
    isLoading: false,
    data: {
      data: []
    },
    isError: false
  }
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getFranchiseList.pending, state => {
      state.franchiselist.isLoading = true;
      state.franchiselist.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getFranchiseList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.franchiselist.isLoading = false;
        state.franchiselist.data = action.payload;
        state.franchiselist.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getFranchiseList.rejected, state => {
      state.franchiselist.isLoading = false;
      state.franchiselist.isError = true;
    });
    builder.addCase(getAgentsList.pending, state => {
      state.agentlist.isLoading = true;
      state.agentlist.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getAgentsList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.agentlist.isLoading = false;
        state.agentlist.data = action.payload;
        state.agentlist.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getAgentsList.rejected, state => {
      state.agentlist.isLoading = false;
      state.agentlist.isError = true;
    });
    builder.addCase(getActiveCountry.pending, state => {
      state.countrylist.isLoading = true;
      state.countrylist.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getActiveCountry.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.countrylist.isLoading = false;
        state.countrylist.data = action.payload;
        state.countrylist.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getActiveCountry.rejected, state => {
      state.countrylist.isLoading = false;
      state.countrylist.isError = true;
    });
    builder.addCase(getProfileList.pending, state => {
      state.profilelist.isLoading = true;
      state.profilelist.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getProfileList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.profilelist.isLoading = false;
        state.profilelist.data = action.payload;
        state.profilelist.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getProfileList.rejected, state => {
      state.profilelist.isLoading = false;
      state.profilelist.isError = true;
    });
    builder.addCase(getCountryFranchiseList.pending, state => {
      state.countryFranchiselist.isLoading = true;
      state.countryFranchiselist.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getCountryFranchiseList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.countryFranchiselist.isLoading = false;
        state.countryFranchiselist.data = action.payload;
        state.countryFranchiselist.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getCountryFranchiseList.rejected, state => {
      state.countryFranchiselist.isLoading = false;
      state.countryFranchiselist.isError = true;
    });
    //global search
    builder.addCase(getGlobalSearch.pending, state => {
      state.globalSearch.isLoading = true;
      state.globalSearch.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getGlobalSearch.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.globalSearch.isLoading = false;
        state.globalSearch.data = action.payload;
        state.globalSearch.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getGlobalSearch.rejected, state => {
      state.globalSearch.isLoading = false;
      state.globalSearch.isError = true;
    });
    //get permission api lists
    builder.addCase(getPermissions.pending, state => {
      state.permission.isLoading = true;
      state.permission.isError = false; // Reset isError on pending
    });
    builder.addCase(getPermissions.fulfilled, (state: any, action: any) => {
      state.permission.isLoading = false;
      //set permissions
      if (action.payload.data) {
        const permission = action.payload.data.permissions;
        const obj: any = {};
        for (const item of permission) {
          const split = item.split('_');
          let key: any = '';
          for (let i = 0; i < split.length - 1; i++) {
            key += `${split[i]}_`;
          }

          obj[key.substring(0, key?.length - 1)] = {
            ...obj[key.substring(0, key?.length - 1)],
            [split[split.length - 1]]: true
          };
        }
        state.permission.data = obj;
      }

      state.permission.isError = false; // Reset isError on fulfilled
    });
    builder.addCase(getPermissions.rejected, state => {
      state.permission.isLoading = false;
      state.permission.isError = true;
    });
  }
});

export default commonSlice.reducer;
