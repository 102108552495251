import CountriesCard from 'components/cards/CountriesCard';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getCountriesDetail } from '../../../../redux/features/Countries/countriesApi';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';

const CountriesDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const country = useSelector((state: RootState) => ({
    country: state.countries.detail.data
    // permission: state.auth.permission
  }));

  useEffect(() => {
    if (id) {
      dispatch(getCountriesDetail(id));
    }
  }, [id]);

  return (
    <div>
      <CountriesCard className="mb-3" country={country?.country.data} />
    </div>
  );
};

export default CountriesDetail;
