/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterButtonGroup from 'components/common/FilterButtonGroup';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import {
  getFranchiseList,
  getAgentsList,
  getPermissions
} from '../../../../redux/features/Common/commonApi';
import { filterMenus } from '../components/tableFilters/filterMenus';
import {
  getDealsGridList,
  getDealsList
} from '../../../../redux/features/Deals/dealsApi';
import DealsTable, { dealsTableColumns } from 'components/tables/DealsTable';
import DealsGrid from './dealsGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip, faList } from '@fortawesome/free-solid-svg-icons';

const DealsList = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const deals = useSelector((state: RootState) => state.deals.list.data);
  const allDeals = useSelector((state: RootState) => state.deals.allList.data);
  const permission = useSelector(
    (state: RootState) => state.common.permission.data
  );

  const franchiseList = useSelector(
    (state: RootState) => state.common.franchiselist.data
  );

  const isLoading = useSelector(
    (state: RootState) => state.deals.list.isLoading
  );

  const agentList = useSelector(
    (state: RootState) => state.common.agentlist.data
  );

  const [check, setCheck] = useState(false);

  const [filter, setFilter] = useState({
    search: '',
    search_franchise: '',
    search_view: '',
    search_agent: '',
    search_status: ''
  });

  const filterMenu = filterMenus(franchiseList, agentList);

  const table = useAdvanceTable({
    data: deals.data,
    columns: dealsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(deals?.meta?.total),
      current_page: Number(deals?.meta?.current_page),
      isLoading: isLoading
    };
  }, [location, deals.meta, isLoading]);

  const handleOrderList = async (): Promise<void> => {
    if (check) {
      dispatch(
        getDealsGridList({
          pageNumber: paginationProps?.page,
          pageSize: paginationProps?.dataPerPage,
          sortField: '',
          sortOrder: '',
          filter: filter
        })
      );
    } else {
      dispatch(
        getDealsList({
          pageNumber: paginationProps?.page,
          pageSize: paginationProps?.dataPerPage,
          sortField: '',
          sortOrder: '',
          filter: filter
        })
      );
    }
  };
  const handleCheck = (value: string) => {
    switch (value) {
      case 'list':
        setCheck(false);
        break;
      case 'card':
        setCheck(true);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    handleOrderList();
  }, [location, filter, check]);
  useEffect(() => {
    dispatch(getFranchiseList());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAgentsList());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getPermissions());
  }, [dispatch]);

  const handleFilter = (key: any, value: any) => {
    setFilter({ ...filter, [key]: value });
  };

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Deals</h2>
        <div className="d-flex flex-wrap gap-3">
          <button
            type="button"
            className="btn btn-primary font-weight-bolder font-size-sm mt-3"
            onClick={() => handleCheck('list')}
          >
            <FontAwesomeIcon icon={faList} />
          </button>
          <button
            type="button"
            className="btn btn-primary font-weight-bolder font-size-sm mt-3"
            onClick={() => handleCheck('card')}
          >
            <FontAwesomeIcon icon={faGrip} />
          </button>
        </div>
        {permission?.deal?.view ? (
          <AdvanceTableProvider {...table}>
            <div className="mb-4 pt-3">
              <div className="d-flex flex-wrap gap-3">
                <div className="scrollbar overflow-hidden-y">
                  <FilterButtonGroup
                    menus={filterMenu}
                    filter={filter}
                    handleFilter={handleFilter}
                  />
                </div>
                <SearchBox
                  placeholder="Search deals"
                  onChange={e => handleFilter('search', e.target.value)}
                />
              </div>
            </div>

            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
              {check ? (
                <DealsGrid entities={allDeals.data} />
              ) : (
                <DealsTable paginationProps={paginationProps} />
              )}
            </div>
          </AdvanceTableProvider>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p>Not Authorized</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DealsList;
