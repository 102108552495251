import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/Table/productsFilter';
import { makeRequest } from 'helpers/apiCall';

export const getProductsList = createAsyncThunk(
  'products/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `product?page=${queryParams.pageNumber}&per_page=${
          queryParams.pageSize
        }&sort_by=${queryParams.sortField}${
          queryParams.offset ? `&offset=${queryParams.offset}` : ''
        }&sort_dir=${queryParams.sortOrder}&search_keyword=${
          queryParams.filter.search
        } &search_franchise=${queryParams.filter.search_franchise}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
