import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/Table/usersFilter';
import { makeRequest } from 'helpers/apiCall';

export const getUsersList = createAsyncThunk(
  'users/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `user?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search} &search_franchise=${queryParams.filter.search_franchise}&search_status=${queryParams.filter.status}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getUsersDetail = createAsyncThunk(
  'users/detail',
  async (id: string) => {
    try {
      const response = await makeRequest('GET', `user/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
