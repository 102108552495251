/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { depotCloseHours, depotOpenHours, weeks } from '../hours';
import { Col, Form, Row } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import CountryPhoneNumber from 'components/common/CountryPhoneNumber';

interface DepotFormProps {
  countryList: any;
  formik: any;
}
export const DepotForm: React.FC<DepotFormProps> = ({
  countryList,
  formik
}) => {
  const handleHours = (e: any, type: number, target: string) => {
    const arr = [...formik.values.depot_hours];

    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].day == type) {
          if (target === 'open_time') {
            if (!e.value) {
              arr[i].open_time = e.value;
              arr[i].close_time = e.value;
            } else {
              arr[i].open_time = e.value;
            }
            arr[i].open_time = e.value;
          } else {
            arr[i].close_time = e.value;
          }
          formik.setFieldValue('depot_hours', arr);
          return;
        }
      }
    }
    const tempObj = {
      day: type,
      open_time: target === 'open_time' ? e.value : '',
      close_time: target === 'close_time' ? e.value : ''
    };
    arr.push(tempObj);

    formik.setFieldValue('depot_hours', arr);
  };
  return (
    <div>
      <Form noValidate>
        <Row className="mb-3 gx-3">
          <Form.Group as={Col} md="4" controlId="exampleFirstName">
            <Form.Label>Depot Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Depot Name"
              {...formik.getFieldProps('name')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="exampleFirstName">
            <Form.Label>Depot Short</Form.Label>
            <Form.Control
              type="text"
              placeholder="Depot Short"
              {...formik.getFieldProps('short_name')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="exampleFirstName">
            <Form.Label>Depot ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Depot ID"
              {...formik.getFieldProps('depot_reference_id')}
              disabled
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="exampleFirstName">
            <Form.Label>Depot Address Line1</Form.Label>
            <Form.Control
              type="text"
              placeholder="Depot Address Line1"
              {...formik.getFieldProps('address_line_1')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="exampleFirstName">
            <Form.Label>Depot Address Line2</Form.Label>
            <Form.Control
              type="text"
              placeholder="Depot Address Line2"
              {...formik.getFieldProps('address_line_2')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="exampleFirstName">
            <Form.Label>Depot Address Line3</Form.Label>
            <Form.Control
              type="text"
              placeholder="Depot Address Line3"
              {...formik.getFieldProps('address_line_3')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="exampleFirstName">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              {...formik.getFieldProps('city')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="exampleFirstName">
            <Form.Label>Post Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Post Code"
              {...formik.getFieldProps('postcode')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Country</Form.Label>
            <ReactSelect
              options={[
                { value: '', label: 'Select' }, // Blank option with label 'Select'
                ...(countryList?.data.map((e: any) => ({
                  value: e.id,
                  label: e.name
                })) || [])
              ]}
              onChange={(e: any) => formik.setFieldValue('country', e.value)}
              placeholder="Select organizer..."
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="exampleFirstName">
            <Form.Label>Manager Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Manager Name"
              {...formik.getFieldProps('manager_name')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="exampleFirstName">
            <Form.Label>Manager Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Manager Email"
              {...formik.getFieldProps('manager_email')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="exampleFirstName">
            <Form.Label>Manager Phone</Form.Label>
            <CountryPhoneNumber
              countryPhoneValue={formik?.values?.phone}
              formik={formik}
              fieldKey={'phone'}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="exampleFirstName">
            <Form.Label>Additional Emails</Form.Label>
            <Form.Control
              type="text"
              placeholder="Additional Emails"
              {...formik.getFieldProps('additional_emails')}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <label> Opening Hours</label>
          {weeks.map((e, index) => (
            <Row key={index}>
              <Form.Group as={Col} md="6">
                <Form.Label>{e.name}</Form.Label>
                <ReactSelect
                  options={[
                    { value: '', label: 'Select' }, // Blank option with label 'Select'
                    ...(depotOpenHours.map((e: any) => ({
                      value: e.id,
                      label: e.name
                    })) || [])
                  ]}
                  onChange={(value: any) =>
                    handleHours(value, e.id, 'open_time')
                  }
                  placeholder="Open"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>{e.name}</Form.Label>
                <ReactSelect
                  options={[
                    { value: '', label: 'Select' }, // Blank option with label 'Select'
                    ...(depotCloseHours.map((e: any) => ({
                      value: e.id,
                      label: e.name
                    })) || [])
                  ]}
                  onChange={(value: any) =>
                    handleHours(value, e.id, 'close_time')
                  }
                  placeholder="Close"
                />
              </Form.Group>
            </Row>
          ))}
        </Row>
      </Form>
    </div>
  );
};
