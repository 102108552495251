/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UilBuilding,
  UilClock,
  UilDollarAlt,
  UilEnvelopeAlt,
  UilGlobe,
  UilPhone,
  UilPostcard
} from '@iconscout/react-unicons';
import classNames from 'classnames';
import Button from 'components/base/Button';
import LeadInfoItem from 'components/info-items/LeadInfoItem';
import moment from 'moment';
import { Card } from 'react-bootstrap';

const AboutLeadCard = ({
  className,
  promotion
}: {
  className?: string;
  promotion?: any;
}) => {
  console.log('promotion', promotion);

  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex align-items-center mb-5">
          <h3>{promotion?.id || '-'}</h3>
          <Button variant="link" className="px-3">
            Edit
          </Button>
        </div>
        <LeadInfoItem
          className="mb-4"
          label="Promotion ID"
          icon={UilEnvelopeAlt}
        >
          <p className="mb-0 text-800">{promotion?.id || '-'}</p>
        </LeadInfoItem>
        <LeadInfoItem
          className="mb-4"
          label="Offer Description"
          icon={UilPhone}
        >
          <p className="mb-0 text-800">{promotion?.invoice_name || '-'}</p>
        </LeadInfoItem>

        <LeadInfoItem className="mb-4" label="Discount Type" icon={UilGlobe}>
          <p className="mb-0 text-800">{promotion?.discount_type || '-'}</p>
        </LeadInfoItem>

        <LeadInfoItem className="mb-4" label="Value" icon={UilBuilding}>
          <p className="mb-0 text-800">
            {promotion?.discount_percentage || '-'}
          </p>
        </LeadInfoItem>

        <LeadInfoItem className="mb-4" label="Valid From" icon={UilPostcard}>
          <p className="mb-0 text-800">
            {moment(promotion?.created_at * 1000).format('DD-MM-YYYY')}
          </p>
        </LeadInfoItem>

        <LeadInfoItem className="mb-4" label="Valid To" icon={UilDollarAlt}>
          <p className="mb-0 text-800">
            {moment(promotion?.updated_at * 1000).format('DD-MM-YYYY')}
          </p>
        </LeadInfoItem>

        <LeadInfoItem className="mb-4" label="Country" icon={UilClock}>
          <p className="mb-0 text-800">{promotion?.country || '-'}</p>
        </LeadInfoItem>
      </Card.Body>
    </Card>
  );
};

export default AboutLeadCard;
