import React from 'react';
import Unicon from 'components/base/Unicon';
import { UilArrowLeft } from '@iconscout/react-unicons';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceTwo } from '@fortawesome/free-solid-svg-icons';

export const OrderId = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <div className="orderInfoHead">
      <ul>
        <li className="mt-1">
          <Unicon icon={UilArrowLeft} />
        </li>
        <li>
          <div className="headopt">
            <h3>#1251</h3>
            <div>
              <Button variant="phoenix-secondary" size="sm">
                <FontAwesomeIcon icon={faDiceTwo} /> Paid
              </Button>
              <Button variant="phoenix-secondary" size="sm">
                <FontAwesomeIcon icon={faDiceTwo} /> Fulfilled
              </Button>
              {/* <Button variant="phoenix-secondary" size="sm">
                Archived
              </Button> */}
            </div>
          </div>
          <div className="time">15 January 2024 at 17:13 from Online Store</div>
        </li>
      </ul>
    </div>
  );
};
