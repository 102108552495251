/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterMenu } from 'components/common/FilterButtonGroup';

export const filterMenus = (franchiseList: any, agentList: any) => {
  const franchiseItems = franchiseList.data.map((franchise: any) => ({
    label: franchise.name,
    id: franchise.id.toString()
  }));

  const agentItems = agentList.data.map((agent: any) => ({
    label: agent.first_name,
    id: agent.id.toString()
  }));

  const viewDealList = [
    {
      label: 'All Deals',
      id: 'all_contacts'
    },
    {
      label: 'My Deals',
      id: 'my_contacts'
    },
    {
      label: 'Unassigned',
      id: 'unassigned'
    },
    {
      label: 'New Last Week',
      id: 'new_last_week'
    },
    {
      label: 'New This Week',
      id: 'new_this_week'
    },
    {
      label: 'Recently Created Deals',
      id: 'recently_created'
    },
    {
      label: 'Recently Modified Deals',
      id: 'recently_modified'
    },
    {
      label: 'Not Yet Viewed',
      id: 'not_yet_viewed'
    }
  ];

  const dealStage = [
    { id: 'Open', label: 'Open' },
    { id: 'Transit', label: 'Transit' },
    { id: 'Pipeline', label: 'Pipeline' },
    { id: 'Close-Won', label: 'Close-Won' },
    { id: 'Close-Lost', label: 'Close-Lost' }
  ];

  const filterMenus: FilterMenu[] = [
    {
      label: 'View',
      id: 'search_view',
      items: viewDealList
    },
    {
      label: 'Deal Stage',
      id: 'search_status',
      items: dealStage
    },
    {
      label: 'Franchise',
      id: 'search_franchise',
      items: franchiseItems
    },
    {
      label: 'Agents',
      id: 'search_agent',
      items: agentItems
    }
  ];

  return filterMenus;
};
