/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Button from 'components/base/Button';
import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

export interface FilterMenu {
  label: string;
  id?: string;
  items: {
    id?: string;
    label: string;
  }[];
}

interface FilterButtonGroupProps {
  menus: FilterMenu[];
  filter?: any;
  handleFilter?: (key?: any, value?: any) => void;
}

const FilterButtonGroup = ({
  menus,
  filter,
  handleFilter = () => {}
}: FilterButtonGroupProps) => {
  console.log(menus, filter);

  return (
    <ButtonGroup className="position-static">
      {menus.map(menu => (
        <Dropdown key={menu.label} as={ButtonGroup} className="position-static">
          <Dropdown.Toggle
            variant="phoenix-secondary"
            className="px-7 flex-shrink-0 dropdown-caret-none"
          >
            {menu.label}
            <FontAwesomeIcon
              icon={faAngleDown}
              className="ms-2"
              transform="down-2"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {menu.items.map((item, subIndex) => (
              <Dropdown.Item
                key={subIndex}
                onClick={() => handleFilter(menu.id, item.id)}
                active={menu && menu.id ? filter[menu.id] === item.id : false}
              >
                {item.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </ButtonGroup>
  );
};

export default FilterButtonGroup;
