import React from 'react';
import Unicon from 'components/base/Unicon';
import { Card } from 'react-bootstrap';
import { UilPen } from '@iconscout/react-unicons';
export const OrderNotes = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <Card className="p-3 mb-3">
      <div className="subHead">
        <h5>Notes</h5>
        <span>
          <Unicon icon={UilPen} />
        </span>
      </div>
      <p className="mb-0">No notes from customer</p>
    </Card>
  );
};
