/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UilMoneyBill,
  UilLanguage,
  UilLocationPoint,
  UilClock
} from '@iconscout/react-unicons';
import classNames from 'classnames';
import Button from 'components/base/Button';
import LeadInfoItem from 'components/info-items/LeadInfoItem';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CountriesCard = ({
  className,
  country
}: {
  className?: string;
  country?: any;
}) => {
  const history = useNavigate();

  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex align-items-center mb-1">
          <h3>{country?.name}</h3>
          <Button variant="link" className="px-3">
            Edit
          </Button>
        </div>
        <div className="d-flex align-items-center mb-5">
          <p
            className={`text-${
              country && country.status && country.status === 1
                ? 'success'
                : 'danger'
            } font-weight-bold mb-0 text-800`}
          >
            {country && country.status && country.status === 1
              ? 'Active'
              : 'Inactive'}
          </p>
        </div>
        <LeadInfoItem
          className="mb-4"
          label="Country Short"
          icon={UilLocationPoint}
        >
          <p className="mb-0 text-800">{country?.short_name}</p>
        </LeadInfoItem>
        <LeadInfoItem className="mb-4" label="Language" icon={UilLanguage}>
          <p className="mb-0 text-800">{country?.language}</p>
        </LeadInfoItem>
        <LeadInfoItem className="mb-4" label="Currency" icon={UilMoneyBill}>
          <p className="mb-0 text-800">{country?.currency}</p>
        </LeadInfoItem>
        <LeadInfoItem className="mb-4" label="Timezone" icon={UilClock}>
          <p className="mb-0 text-800">{country?.timezone}</p>
        </LeadInfoItem>

        <button
          className="btn btn-primary  mt-6 mb-4"
          style={{ width: '30%' }}
          onClick={() =>
            history(
              `/settings/franchises${
                country?.match?.params?.id
                  ? `?country=${country?.match?.params?.id}`
                  : ''
              }`
            )
          }
        >
          FRANCHISES
        </button>
      </Card.Body>
    </Card>
  );
};

export default CountriesCard;
