import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import PromotionsTable, {
  promotionsTableColumns
} from 'components/tables/PromotionsTable';
import { getPromotionsList } from '../../../../redux/features/Settings/Promotions/promotionsApi';

const filterMenus: FilterMenu[] = [
  {
    label: 'Depot status',
    items: [
      {
        label: 'All'
      },
      {
        label: 'Depot Active'
      },
      {
        label: 'Depot InActive'
      }
    ]
  },
  {
    label: 'Franchise',
    items: [
      {
        label: 'Action'
      },
      {
        label: 'Another Action'
      },
      {
        label: 'Something else here'
      }
    ]
  }
];

const PromotionsList = () => {
  const dispatch = useAppDispatch();
  const [pageArray, setPageArray] = useState<{ value: string | null }[]>([]);
  const promotion = useSelector(
    (state: RootState) => state.promotion.list.data
  );

  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const table = useAdvanceTable({
    data: promotion?.data || [],
    columns: promotionsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const value = params.get('offset');
    if (!value) {
      const page = params.get('page');
      const index = Number(page) - 2;

      return {
        page: parseInt(params.get('page') || '1', 10),
        dataPerPage: parseInt(params.get('pageSize') || '10', 10),
        total: Number(promotion?.total),
        current_page: parseInt(params.get('page') || '1', 10),
        nextoffset: promotion?.nextoffset,
        prevoffset: Number(page) === 1 ? '' : pageArray[index]?.value || ''
      };
    }
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(promotion?.total),
      current_page: parseInt(params.get('page') || '1', 10),
      nextoffset: promotion?.nextoffset,
      prevoffset: value ? value : ''
    };
  }, [location, promotion]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  const handleProductList = async (): Promise<void> => {
    const params = new URLSearchParams(location.search);
    const value = params.get('offset');
    if (value) {
      const updatedPageArray = [...pageArray, { value }];
      setPageArray(updatedPageArray);
    }
    dispatch(
      getPromotionsList({
        pageNumber: paginationProps?.page,
        pageSize: paginationProps?.dataPerPage,
        offset: paginationProps?.prevoffset,
        sortField: '',
        sortOrder: '',
        filter: { search: '', search_franchise: '' }
      })
    );
  };
  useEffect(() => {
    handleProductList();
  }, [location]);
  console.log('arr', pageArray);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Promotion</h2>
        {/* <FilterTab tabItems={tabItems} className="mb-2" /> */}

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search depots"
                onChange={handleSearchInputChange}
              />
              <div className="scrollbar overflow-hidden-y">
                <FilterButtonGroup menus={filterMenus} />
              </div>
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  New Product
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <PromotionsTable paginationProps={paginationProps} />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default PromotionsList;
