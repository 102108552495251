import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProductsList } from './productsApi';

interface ProductState {
  list: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
}

const initialState: ProductState = {
  list: {
    isLoading: false,
    data: {
      data: [],
      meta: {}
    },
    isError: false
  }
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getProductsList.pending, state => {
      state.list.isLoading = true;
      state.list.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getProductsList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
        state.list.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getProductsList.rejected, state => {
      state.list.isLoading = false;
      state.list.isError = true;
    });
  }
});

export default productsSlice.reducer;
