/* eslint-disable @typescript-eslint/no-explicit-any */
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { defaultBreadcrumbItems } from 'data/commonData';
import { useEffect, useState } from 'react';
import { RootState, useAppDispatch } from '../../../redux/store';
import { shallowEqual, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { assignAvailability } from '../../../redux/features/Agents/agentsApi';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import CustomeDialog from 'components/modals/CustomeDialog';
import { getAgentsList } from '../../../redux/features/Common/commonApi';
import { enqueueSnackbar } from 'notistack';
interface formValues {
  agent_availability: any[]; // Array of numbers
}
const SalesAgentAvailability = () => {
  const initialValues: formValues = {
    agent_availability: []
  };
  const dispatch = useAppDispatch();
  const [dialog, setDialog] = useState({ type: false });
  const location = useLocation();
  const { agentList } = useSelector(
    (state: RootState) => ({ agentList: state.common.agentlist.data.data }),
    shallowEqual
  );
  const handleAgentList = async (): Promise<void> => {
    dispatch(getAgentsList());
  };
  useEffect(() => {
    handleAgentList();
  }, [location]);

  const handleSubmit = async () => {
    try {
      const response = await assignAvailability(formik.values);
      if (response) {
        setDialog({ type: false });
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      console.error('error updating agents', error.response.data.message);
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit
  });

  const handleChange = (e: any, data: any, value: any) => {
    const agents: any = [...formik.values.agent_availability];
    if (value > -1) {
      agents[value]['is_available'] = e.target.checked ? 1 : '0';
    } else {
      const item = {
        id: data.id,
        is_available: e.target.checked ? 1 : '0'
      };
      agents.push(item);
    }

    formik.setFieldValue('agent_availability', agents);
  };

  const handleAllAgent = (e: any) => {
    const arr = [];
    if (e.target.checked) {
      for (const items of agentList) {
        const data = {
          id: items.id,
          is_available: 1
        };
        arr.push(data);
      }
    }
    formik.setFieldValue('agent_availability', arr);
  };

  useEffect(() => {
    if (agentList.length > 0) {
      const arr = [];
      for (const items of agentList) {
        const data = {
          id: items.id,
          is_available: items?.is_available === 1 ? 1 : '0'
        };
        arr.push(data);
      }
      formik.setFieldValue('agent_availability', arr);
    }
  }, [agentList, agentList.length]);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Sales Agent Availability</h2>
        <form onSubmit={formik.handleSubmit}>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="All Franchises"
            checked={
              formik.values?.agent_availability.length > 0 &&
              formik.values?.agent_availability.length === agentList.length &&
              formik.values?.agent_availability.every(
                (e: any) => e?.is_available === 1
              )
            }
            onChange={e => {
              handleAllAgent(e);
            }}
          />
          {agentList && agentList.length
            ? agentList.map((data: any, index: any) => {
                const value = formik?.values?.agent_availability.findIndex(
                  (e: any) => e.id === data.id
                );
                return (
                  <div className="col-md-12" key={index}>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={data.first_name + data.last_name}
                      defaultChecked={data.is_available}
                      checked={
                        Number(
                          formik.values?.agent_availability[value]?.is_available
                        ) > 0
                          ? true
                          : false
                      }
                      onChange={e => {
                        handleChange(e, data, value);
                      }}
                    />
                  </div>
                );
              })
            : ''}
          <button
            type="button"
            className="btn btn-primary font-weight-bolder font-size-sm mt-3"
            onClick={() => setDialog({ type: true })}
          >
            SAVE
          </button>
        </form>
        <CustomeDialog
          dialog={dialog}
          setDialog={setDialog}
          header="Save Changes?"
          mainText="Are you sure you want to save changes made to Availability?"
          handleAction={handleSubmit}
        />
      </div>
    </div>
  );
};

export default SalesAgentAvailability;
