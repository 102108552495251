import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface UsersDropdownItemsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUrl: any;
}

const UsersDropdownItems: React.FC<UsersDropdownItemsProps> = ({ setUrl }) => {
  console.log(setUrl.url);
  const navigate = useNavigate();
  return (
    <>
      <Dropdown.Item onClick={() => navigate(setUrl.url)}>View</Dropdown.Item>
    </>
  );
};

export default UsersDropdownItems;
