export default [
  {
    from: 'v1.0.0',
    to: 'v1.1.0',
    new: [
      'src/assets/img/bg/10-dark.png',
      'src/assets/img/bg/40.png',
      'src/assets/img/bg/8-dark.png',
      'src/assets/img/bg/9-dark.png',
      'src/assets/img/bg/bg-11-dark.png',
      'src/assets/img/spot-illustrations/bag-2-dark.png',
      'src/assets/img/spot-illustrations/rocket-dark.png',
      'src/assets/img/spot-illustrations/shield-2-dark.png',
      'src/assets/img/spot-illustrations/star-dark.png',
      'src/components/base/Unicon.tsx',
      'src/components/cards/AboutLeadCard.tsx',
      'src/components/cards/CrmStatCard.tsx',
      'src/components/cards/DealProfileCard.tsx',
      'src/components/cards/DealsPrintingDimensionsCard.tsx',
      'src/components/cards/IconCard.tsx',
      'src/components/cards/LeadAddressCard.tsx',
      'src/components/cards/LeadProfileCard.tsx',
      'src/components/cards/MeetingCard.tsx',
      'src/components/cards/ReportCard.tsx',
      'src/components/charts/e-charts/AdClicksChart.tsx',
      'src/components/charts/e-charts/AnalyticsCallCampaignChart.tsx',
      'src/components/charts/e-charts/AnalyticsEmailCampaignChart.tsx',
      'src/components/charts/e-charts/AnalyticsSalesTrendsChart.tsx',
      'src/components/charts/e-charts/BasicLineChart.tsx',
      'src/components/charts/e-charts/ContactsBySourceChart.tsx',
      'src/components/charts/e-charts/ContactsCreatedChart.tsx',
      'src/components/charts/e-charts/LeadConversationChart.tsx',
      'src/components/charts/e-charts/MarketingCampaignChart.tsx',
      'src/components/charts/e-charts/ReportDetailsChart.tsx',
      'src/components/charts/e-charts/RevenueTargetChart.tsx',
      'src/components/common/PhoenixLoader.tsx',
      'src/components/forms/AddressInformation.tsx',
      'src/components/forms/DealsOtherInformation.tsx',
      'src/components/forms/LeadInformationForm.tsx',
      'src/components/grid-list-items/ContactSourceItem.tsx',
      'src/components/icons/CircleProgress.tsx',
      'src/components/info-items/LeadInfoItem.tsx',
      'src/components/list-items/LeadSources.tsx',
      'src/components/list-items/PricingPackageList.tsx',
      'src/components/modals/LeadsFilterModal.tsx',
      'src/components/modals/ReportFilterModal.tsx',
      'src/components/modals/ReportsFilterModal.tsx',
      'src/components/modules/auth/*.tsx',
      'src/components/modules/crm/**/*.tsx',
      'src/components/pricing-items/PricingGridItem.tsx',
      'src/components/progress-bars/DealForecasrProgressBar.tsx',
      'src/components/stats/AnalyticsStats.tsx',
      'src/components/tables/DealDetailsCallTable.tsx',
      'src/components/tables/DealForecastTable.tsx',
      'src/components/tables/DealsReportTable.tsx',
      'src/components/tables/LeadDealsTable.tsx',
      'src/components/tables/LeadEmailsTable.tsx',
      'src/components/tables/LeadsTable.tsx',
      'src/components/tables/ReportDetailsTable.tsx',
      'src/components/timelines/DealDetailsTimeline.tsx',
      'src/layouts/AuthCardLayout.tsx',
      'src/layouts/AuthSimpleLayout.tsx',
      'src/layouts/AuthSplitLayout.tsx',
      'src/pages/apps/crm/*.tsx',
      'src/pages/dashboard/Crm.tsx',
      'src/pages/modules/components/FontAwesomeExample.tsx',
      'src/pages/modules/components/UniconsExample.tsx',
      'src/pages/pages/authentication/card/*.tsx',
      'src/pages/pages/authentication/split/*.tsx',
      'src/pages/pages/pricing/PricingGrid.tsx',
      'src/pages/documentation/Migrations.tsx',
      'src/data/crm/*.ts',
      'src/data/icons/*.ts',
      'src/data/changelog.ts',
      'src/data/migration.ts'
    ],
    update: [
      '.eslintignore',
      '.eslintrc.json',
      'package.json',
      'src/Routes.tsx',
      'src/assets/scss/theme/_crm.scss',
      'src/assets/scss/theme/_mixed.scss',
      'src/assets/scss/theme/_offcanvas.scss',
      'src/assets/scss/theme/_pricing.scss',
      'src/assets/scss/theme/_progress.scss',
      'src/assets/scss/theme/_table.scss',
      'src/assets/scss/theme/_theme.scss',
      'src/assets/scss/theme/_variables.scss',
      'src/assets/scss/theme/helpers/_borders.scss',
      'src/components/base/AdvanceTable.tsx',
      'src/components/base/AdvanceTableFooter.tsx',
      'src/components/base/Dropzone.tsx',
      'src/components/base/MapBox.tsx',
      'src/components/base/PhoenixDocCard.tsx',
      'src/components/base/Rating.tsx',
      'src/components/base/ReactSelect.tsx',
      'src/components/base/RevealDropdown.tsx',
      'src/components/base/Swiper.tsx',
      'src/components/cards/CustomerProfileCard.tsx',
      'src/components/cards/EarlyBirdCard.tsx',
      'src/components/cards/EcoimDefaultAddressCard.tsx',
      'src/components/cards/EcomCartSummaryCard.tsx',
      'src/components/cards/EcomProfileCard.tsx',
      'src/components/cards/EventTitleCard.tsx',
      'src/components/cards/SocialCoverCard.tsx',
      'src/components/cards/SocialProfileCard.tsx',
      'src/components/cards/UsuallyBoughtTogetherCard.tsx',
      'src/components/charts/e-charts/EcomProjectionVsActualChart.tsx',
      'src/components/charts/e-charts/ProjectElevenProgressChart.tsx',
      'src/components/common/AttachmentPreview.tsx',
      'src/components/common/AuthSocialButtons.tsx',
      'src/components/common/AvatarDropdown.tsx',
      'src/components/common/AvatarUpload.tsx',
      'src/components/common/CommentForm.tsx',
      'src/components/common/CoverUpload.tsx',
      'src/components/common/DropdownSearchBox.tsx',
      'src/components/common/EditableDetailsField.tsx',
      'src/components/common/FilterButtonGroup.tsx',
      'src/components/common/FormCollapse.tsx',
      'src/components/common/ImageAttachmentPreview.tsx',
      'src/components/common/NotificationItem.tsx',
      'src/components/common/ProductCard.tsx',
      'src/components/common/QuantityButtons.tsx',
      'src/components/common/SearchBox.tsx',
      'src/components/common/SearchResult.tsx',
      'src/components/common/StoreItem.tsx',
      'src/components/common/WarningMessage.tsx',
      'src/components/cta/EcomBecomeMember.tsx',
      'src/components/cta/FaqCta.tsx',
      'src/components/footers/EcommerceFooter.tsx',
      'src/components/forms/EventCustomFields.tsx',
      'src/components/forms/EventTicketPricing.tsx',
      'src/components/forms/FeedTextarea.tsx',
      'src/components/forms/SettingsChangePassword.tsx',
      'src/components/forms/SettingsCompanyInfo.tsx',
      'src/components/forms/SettingsPersonalInfo.tsx',
      'src/components/forms/SettingsSocial.tsx',
      'src/components/list-items/EventItem.tsx',
      'src/components/list-items/ProductReview.tsx',
      'src/components/list-items/ProfileNavigation.tsx',
      'src/components/list-items/SocialMessages.tsx',
      'src/components/modules/chat/ChatSidebar.tsx',
      'src/components/modules/chat/chat-content/ChatContentFooter.tsx',
      'src/components/modules/chat/chat-content/ChatContentHeader.tsx',
      'src/components/modules/chat/conversation-details/SharedFiles.tsx',
      'src/components/modules/chat/conversation-details/SharedMedia.tsx',
      'src/components/modules/chat/conversation-details/index.tsx',
      'src/components/modules/e-commerce/OrderDeliveryDetails.tsx',
      'src/components/modules/e-commerce/ProductDescription.tsx',
      'src/components/modules/e-commerce/ProductDetailsTab.tsx',
      'src/components/modules/e-commerce/profile/ProfileDetailsTab.tsx',
      'src/components/modules/email/EmailRow.tsx',
      'src/components/modules/email/EmailSidebar.tsx',
      'src/components/modules/email/InboxToolbar.tsx',
      'src/components/modules/events/EventsOrganized.tsx',
      'src/components/modules/events/Location.tsx',
      'src/components/modules/events/ResponsesAndShare.tsx',
      'src/components/modules/landing/default/Cta.tsx',
      'src/components/modules/landing/default/HeroHeader.tsx',
      'src/components/modules/landing/default/address/HelpCenter.tsx',
      'src/components/modules/landing/default/blog/Blog.tsx',
      'src/components/modules/landing/default/blog/BlogItem.tsx',
      'src/components/modules/landing/default/features/FeatureSection.tsx',
      'src/components/modules/landing/default/features/Features.tsx',
      'src/components/modules/project-management/ProjectsTopSection.tsx',
      'src/components/modules/project-management/board-view/ActionSection.tsx',
      'src/components/modules/project-management/board-view/BoardViewModal.tsx',
      'src/components/modules/project-management/board-view/CoverImage.tsx',
      'src/components/modules/project-management/card-view/CardViewItem.tsx',
      'src/components/modules/project-management/card-view/CardViewModal.tsx',
      'src/components/modules/project-management/dashboard/Stat.tsx',
      'src/components/modules/project-management/project-details/ProjectDetailsSummary.tsx',
      'src/components/modules/project-management/todo-list/FileListItem.tsx',
      'src/components/modules/project-management/todo-list/SubTask.tsx',
      'src/components/modules/project-management/todo-list/TodoItemDetailsModal.tsx',
      'src/components/modules/project-management/todo-list/TodoItemDetailsOffcanvas.tsx',
      'src/components/modules/project-management/todo-list/TodoList.tsx',
      'src/components/modules/project-management/todo-list/TodoListItem.tsx',
      'src/components/modules/social/NavbarBottom.tsx',
      'src/components/modules/social/PostCard.tsx',
      'src/components/modules/social/PostComments.tsx',
      'src/components/navbars/ecommerce/EcommerceNavbar.tsx',
      'src/components/navbars/nav-items/NavItemsSlim.tsx',
      'src/components/navbars/navbar-horizontal/NavbarTopNav.tsx',
      'src/components/navbars/navbar-vertical/NavbarVerticalMenu.tsx',
      'src/components/pricing-items/PricingColumnItem.tsx',
      'src/components/settings-panel/SettingsPanel.tsx',
      'src/components/sliders/EcomBestOffers.tsx',
      'src/components/sliders/EcomTopDeals.tsx',
      'src/components/sliders/EcomTopElectronics.tsx',
      'src/components/stats/EcomStats.tsx',
      'src/components/tables/EcomCartTable.tsx',
      'src/components/tables/EcomLatestReviewsTable.tsx',
      'src/components/tables/EcomWishlistTable.tsx',
      'src/components/tables/ProjectDashboardTable.tsx',
      'src/components/tabs/InventoryTab.tsx',
      'src/components/timelines/OrderTrackingTimeline.tsx',
      'src/data/chat.ts',
      'src/data/email.tsx',
      'src/data/landing/pricingData.ts',
      'src/data/pricing.ts',
      'src/data/project-management/actions.ts',
      'src/data/project-management/activityTimelineData.ts',
      'src/data/project-management/comments.tsx',
      'src/data/project-management/todoListData.ts',
      'src/data/search.ts',
      'src/data/social/dropdownData.ts',
      'src/data/timelineData.ts',
      'src/helpers/echart-utils.ts',
      'src/helpers/utils.ts',
      'src/hooks/useAdvanceTable.tsx',
      'src/index.tsx',
      'src/layouts/EmailLayout.tsx',
      'src/pages/ComingSoon.tsx',
      'src/pages/Members.tsx',
      'src/pages/apps/crm/Leads.tsx',
      'src/pages/apps/e-commerce/admin/CustomerDetails.tsx',
      'src/pages/apps/e-commerce/admin/Customers.tsx',
      'src/pages/apps/e-commerce/admin/OrderDetails.tsx',
      'src/pages/apps/e-commerce/admin/Orders.tsx',
      'src/pages/apps/e-commerce/admin/Products.tsx',
      'src/pages/apps/e-commerce/admin/Refund.tsx',
      'src/pages/apps/e-commerce/customer/Invoice.tsx',
      'src/pages/apps/e-commerce/customer/OrderTracking.tsx',
      'src/pages/apps/e-commerce/customer/ProductsFilter.tsx',
      'src/pages/apps/e-commerce/customer/Profile.tsx',
      'src/pages/apps/email/Compose.tsx',
      'src/pages/apps/email/EmailDetail.tsx',
      'src/pages/apps/project-management/ProjectBoardView.tsx',
      'src/pages/apps/project-management/ProjectCardView.tsx',
      'src/pages/apps/project-management/ProjectDetails.tsx',
      'src/pages/apps/project-management/ProjectListView.tsx',
      'src/pages/apps/project-management/ProjectTodoList.tsx',
      'src/pages/modules/components/BootstrapCarousel.tsx',
      'src/pages/modules/components/ButtonExample.tsx',
      'src/pages/modules/components/FeatherIconsExample.tsx',
      'src/pages/modules/components/PaginationExample.tsx',
      'src/pages/modules/forms/advance/EmojiButtonExample.tsx',
      'src/pages/pages/Starter.tsx',
      'src/pages/pages/authentication/simple/ForgotPassword.tsx',
      'src/pages/pages/authentication/simple/LockScreen.tsx',
      'src/pages/pages/authentication/simple/ResetPassword.tsx',
      'src/pages/pages/authentication/simple/SignIn.tsx',
      'src/pages/pages/authentication/simple/SignOut.tsx',
      'src/pages/pages/authentication/simple/SignUp.tsx',
      'src/pages/pages/authentication/simple/TwoFA.tsx',
      'src/pages/pages/pricing/PricingColumn.tsx',
      'src/providers/PhoenixDocProvider.tsx',
      'src/sitemap.tsx',
      'types/phoenix-module.d.ts',
      'types/react-table.d.ts',
      'src/pages/documentation/ChangeLog.tsx'
    ],
    delete: [
      'src/helpers/initFA.ts',
      'src/layouts/SimpleAuthLayout.tsx',
      'src/pages/modules/components/FontAwesome.tsx',
      'src/pages/modules/components/Unicons.tsx'
    ]
  }
];
