/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';
import ChargbeePagination from 'components/base/ChargbeePagination';

interface Products {
  item: any;
  id: string;
  name: string;
  short_name: string;
  manager_name: string;
  phone: string;
  manager_email: string;
  status: number;
}

interface ProductsTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
  }; // Replace 'YourPaginationPropsType' with the actual type
}

export const productTableColumns: ColumnDef<Products>[] = [
  {
    accessorKey: 'id',
    header: 'PRODUCT ID',
    cell: ({ row: { original } }) => {
      const { item } = original;
      return <>{item.id}</>;
    },
    meta: {
      headerProps: { style: { width: '30%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'name',
    header: 'PRODUCT NAME',
    cell: ({ row: { original } }) => {
      const { item } = original;
      return <>{item?.name}</>;
    },
    meta: {
      headerProps: { style: { width: '40%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'updated_at',
    header: 'PRODUCT TYPE',
    cell: ({ row: { original } }) => {
      const { item } = original;
      return (
        <>
          {item?.type === 'charge'
            ? 'Charge'
            : item?.type === 'plan'
            ? 'Plan'
            : item?.type === 'addon'
            ? 'Addon'
            : '-'}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },

  {
    id: 'status',
    // accessorFn: ({ payment_status }) => payment_status.label,
    header: 'STATUS',
    cell: ({ row: { original } }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { item } = original;
      if (item?.status === 'active') {
        return (
          <Badge
            bg={'success'}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                icon={'feather-check'}
                size={12.8}
                className="ms-1"
              />
            }
          >
            Active
          </Badge>
        );
      } else {
        return (
          <Badge
            bg={'danger'}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                icon={'feather-check'}
                size={12.8}
                className="ms-1"
              />
            }
          >
            InActive
          </Badge>
        );
      }
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3' }
    }
  }
];

const ProductsTable: React.FC<ProductsTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <ChargbeePagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default ProductsTable;
