/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import {
  faCalendarCheck,
  faContactCard,
  faMoneyCheckDollar,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import SVG from 'react-inlinesvg';
// import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useNavigate } from 'react-router-dom';
// import { useIntl } from 'react-intl';
interface DealsCardProps {
  item: any;
}
const DealCard: React.FC<DealsCardProps> = ({ item }) => {
  // const intl = useIntl();
  const navigate = useNavigate();
  return (
    <div
      className="card card-custom card-stretch gutter-b "
      onClick={() => navigate(`/deal-view/${item?.id}`)}
    >
      <div className="card-body">
        <ul>
          <li>
            <figure>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  {/* <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl(
                      '/media/svg/icons/Communication/Contact1.svg'
                    )}faGrip
                  ></SVG> */}
                  <FontAwesomeIcon icon={faContactCard} />
                </span>
              </span>
            </figure>
            <h2>
              {item?.user_data?.first_name || '-'}&nbsp;
              {item?.user_data?.last_name || ' '}
            </h2>
          </li>
          <li>
            <figure>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  {/* <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')}
                  ></SVG> */}
                  <FontAwesomeIcon icon={faMoneyCheckDollar} />
                </span>
              </span>
            </figure>
            <span className="text-muted">{item?.amount}</span>
          </li>
          <li>
            <figure>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  {/* <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl(
                      '/media/svg/icons/Layout/Layout-top-panel-6.svg'
                    )}
                  ></SVG> */}
                  <FontAwesomeIcon icon={faCalendarCheck} />
                </span>
              </span>
            </figure>
            <span className="text-muted">
              Closing Date :
              <br />
              {item && item.closed_at ? item.closed_at : '-'}
            </span>
          </li>
          <li>
            <figure>
              <span className="symbol-label ml-2">
                <span className="svg-icon svg-icon-lg svg-icon-primary">
                  {/* <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}
                  ></SVG> */}
                  <FontAwesomeIcon icon={faUser} />
                </span>
              </span>
            </figure>
            <span className="text-muted">
              {item && item.agent_data && item.agent_data.first_name
                ? item.agent_data.first_name
                : ' '}{' '}
              {item && item.agent_data && item.agent_data.last_name
                ? item.agent_data.last_name
                : ''}{' '}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DealCard;
