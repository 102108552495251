import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequest } from 'helpers/apiCall';

export const getFranchiseList = createAsyncThunk('franchise/list', async () => {
  try {
    const response = await makeRequest(
      'GET',
      `contact/postcode-franchise-list`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getAgentsList = createAsyncThunk('agents/list', async () => {
  try {
    const response = await makeRequest('GET', `contact/agent-list`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});
export const getActiveCountry = createAsyncThunk('country/list', async () => {
  try {
    const response = await makeRequest('GET', `countries`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});
export const getProfileList = createAsyncThunk('profile/list', async () => {
  try {
    const response = await makeRequest('GET', `user/profile-dropdown/list`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});
export const getCountryFranchiseList = createAsyncThunk(
  'country-franchise/list',
  async (id: string) => {
    try {
      const response = await makeRequest(
        'GET',
        `promotion/country-franchise/list?country_id=${id}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getPermissions = createAsyncThunk('user/permissions', async () => {
  try {
    const response = await makeRequest('GET', `user-permissions-list`);
    const permissions = response.data;
    return permissions;
  } catch (error) {
    console.log(error);
  }
});
export const getGlobalSearch = createAsyncThunk(
  'global/search',
  async (search: string) => {
    try {
      const response = await makeRequest(
        'GET',
        `global-search-union?global_search=${search}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
