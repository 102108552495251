/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPromotionsDetail, getPromotionsList } from './promotionsApi';

interface PromotionState {
  list: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
  detail: {
    isLoading: boolean;
    isError: boolean;
    data: any;
  };
}

const initialState: PromotionState = {
  list: {
    isLoading: false,
    data: {
      data: [],
      meta: {}
    },
    isError: false
  },
  detail: {
    isLoading: false,
    data: {},
    isError: false
  }
};

export const promotionsSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPromotionsList.pending, state => {
      state.list.isLoading = true;
      state.list.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getPromotionsList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
        state.list.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getPromotionsList.rejected, state => {
      state.list.isLoading = false;
      state.list.isError = true;
    });
    builder.addCase(getPromotionsDetail.pending, state => {
      state.detail.isLoading = true;
      state.detail.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getPromotionsDetail.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.detail.data = action.payload;
      }
    );
    builder.addCase(getPromotionsDetail.rejected, state => {
      state.detail.isLoading = false;
      state.detail.isError = true;
    });
  }
});

export default promotionsSlice.reducer;
