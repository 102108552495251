import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/customersFilter';
import { makeRequest } from 'helpers/apiCall';

export const getCustomersList = createAsyncThunk(
  'customers/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `customer?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}&search_view=${queryParams.filter.search_view}&search_agent=${queryParams.filter.search_agent}&search_franchise=${queryParams.filter.search_franchise}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
