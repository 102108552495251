export default [
  {
    version: '1.1.0',
    title: 'Theseus',
    publishDate: '12 October, 2023',
    logs: {
      new: [
        'Dashboard : <code>CRM</code>',
        'PAGE : <code>CRM/Analytics</code>',
        'PAGE : <code>CRM/Deal-Details</code>',
        'PAGE : <code>CRM/Lead</code>',
        'PAGE : <code>CRM/Lead-Details</code>',
        'PAGE : <code>CRM/Reports</code>',
        'PAGE : <code>CRM/Report-Details</code>',
        'PAGE : <code>CRM/Add-Contact</code>',
        'PAGE : <code>pricing/pricing-grid</code>',
        'PAGE : <code>authentication/split/sign-in</code>',
        'PAGE : <code>authentication/split/sign-up</code>',
        'PAGE : <code>authentication/split/sign-out</code>',
        'PAGE : <code>authentication/split/forgot-password</code>',
        'PAGE : <code>authentication/split/reset-password</code>',
        'PAGE : <code>authentication/split/lock-screen</code>',
        'PAGE : <code>authentication/split/2FA</code>',
        'PAGE : <code>authentication/card/sign-in</code>',
        'PAGE : <code>authentication/card/sign-up</code>',
        'PAGE : <code>authentication/card/sign-out</code>',
        'PAGE : <code>authentication/card/forgot-password</code>',
        'PAGE : <code>authentication/card/reset-password</code>',
        'PAGE : <code>authentication/card/lock-screen</code>',
        'PAGE : <code>authentication/card/2FA</code>',
        'PAGE : <code>migrations</code>',
        'COMPONENT : <code>Unicon</code>',
        'COMPONENT : <code>PhoenixLoader</code>'
      ],
      update: [
        'Migrated from Font Awesome React  <code>Global Use</code> to <code>Individual Use</code> for improved icon management.',
        'Added <code>footerProps</code> in <code>AdvanceTable</code> columns definition.',
        'Example added for all <code>Icon</code> sets.'
      ],
      fix: ['Minor Bugs']
    }
  }
];
