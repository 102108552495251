import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
// import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
// import { getCountryList } from './service';
import ProfilesTable, {
  profilesTableColumns
} from 'components/tables/ProfilesTable';
import { useLocation } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getProfilesList } from '../../../../redux/features/Profiles/profilesApi';

// const tabItems: FilterTabItem[] = [
//   {
//     label: 'All',
//     value: 'all',
//     count: 68817
//   },
//   {
//     label: 'Pending payment',
//     value: 'pending_payment',
//     count: 6
//   },
//   {
//     label: 'Unfulfilled',
//     value: 'unfulfilled',
//     count: 17
//   },
//   {
//     label: 'Completed',
//     value: 'completed',
//     count: 6810
//   },
//   {
//     label: 'Refunded',
//     value: 'refunded',
//     count: 8
//   },
//   {
//     label: 'Failed',
//     value: 'failed',
//     count: 2
//   }
// ];

const filterMenus: FilterMenu[] = [
  {
    label: 'Profile Active',
    items: [
      {
        label: 'Active'
      },
      {
        label: 'InActive'
      }
    ]
  }
];

const ProfilesList = () => {
  const dispatch = useAppDispatch();
  const profiles = useSelector((state: RootState) => state.profiles.list.data);
  const isLoading = useSelector(
    (state: RootState) => state.profiles.list.isLoading
  );

  // useEffect(() => {
  //   dispatch(
  //     getDepotsList({
  //       pageNumber: paginationProps?.page,
  //       pageSize: paginationProps?.dataPerPage,
  //       sortField: '',
  //       sortOrder: '',
  //       filter: { search: '', search_franchise: '' }
  //     })
  //   );
  // }, [dispatch]);
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState({
    list: [],
    meta: { total: null, current_page: 1 }
  });

  const table = useAdvanceTable({
    data: profiles?.data || [],
    columns: profilesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const paginationProps = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      page: parseInt(params.get('page') || '1', 10),
      dataPerPage: parseInt(params.get('pageSize') || '10', 10),
      total: Number(profiles?.meta?.total),
      current_page: Number(profiles?.meta?.current_page),
      isLoading: isLoading
    };
  }, [location, profiles?.meta]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  const handleCountryList = async (): Promise<void> => {
    dispatch(
      getProfilesList({
        pageNumber: paginationProps?.page,
        pageSize: paginationProps?.dataPerPage,
        sortField: '',
        sortOrder: '',
        filter: { search: '', search_franchise: '', status: '' }
      })
    );
  };
  useEffect(() => {
    handleCountryList();
  }, [location]);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-4">Profile</h2>
        {/* <FilterTab tabItems={tabItems} className="mb-2" /> */}

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search profile"
                onChange={handleSearchInputChange}
              />
              <div className="scrollbar overflow-hidden-y">
                <FilterButtonGroup menus={filterMenus} />
              </div>
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faTrash} className="fs-9 me-2" />
                  Delete
                </Button>
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  New Profile
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <ProfilesTable paginationProps={paginationProps} />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default ProfilesList;
