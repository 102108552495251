import { createAsyncThunk } from '@reduxjs/toolkit';
import { QueryParams } from 'components/interface/franchisesFilter';
import { makeRequest } from 'helpers/apiCall';

export const getFranchisesList = createAsyncThunk(
  'franchises/list',
  async (queryParams: QueryParams) => {
    try {
      const response = await makeRequest(
        'GET',
        `franchise?page=${queryParams.pageNumber}&per_page=${queryParams.pageSize}&sort_by=${queryParams.sortField}&sort_dir=${queryParams.sortOrder}&search_keyword=${queryParams.filter.search}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
