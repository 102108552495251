/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import CustomPagination from 'components/base/CustomPagination';
import moment from 'moment';
import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';

interface Tasks {
  task_type: string;
  priority: string;
  task_name: string;
  task_owner_id: number;
  deal_user: string;
  contact_user: string;
  deal_data: {
    franchise: {
      name: string;
    };
    agent: {
      first_name: string;
    };
  };
  contact_data: {
    franchise: {
      name: string;
    };
    agent: {
      first_name: string;
    };
  };
  agent: string;
  stage: string;
  created_at: string;
  due_date: string;
  time: string;
  is_unassigned: number;
  owner_data: {
    first_name: string;
    last_name: string;
  };
}
interface TasksTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}
export const tasksTableColumns: ColumnDef<Tasks>[] = [
  {
    accessorKey: 'task_type',
    header: 'TASK TYPE',
    cell: ({ row: { original } }) => {
      const { task_type } = original;
      return <>{task_type}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'priority',
    header: 'PRIORITY',
    cell: ({ row: { original } }) => {
      const { priority } = original;
      return (
        <Badge
          bg={
            priority === 'Hot'
              ? 'success'
              : priority === 'Medium'
              ? 'warning'
              : priority === 'Low'
              ? 'danger'
              : 'primary'
          }
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
          icon={
            <FeatherIcon icon={'feather-check'} size={12.8} className="ms-1" />
          }
        >
          {priority}
        </Badge>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'task_name',
    header: 'TASK NAME',
    cell: ({ row: { original } }) => {
      const { task_name } = original;
      return <>{task_name || '-'}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'task_owner_id',
    header: 'task_owner_id',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original && original.is_unassigned === 0 ? (
            <>
              {original && original.owner_data && original.owner_data.first_name
                ? original.owner_data.first_name
                : ' - '}
              &nbsp;
              {original?.owner_data?.last_name}
            </>
          ) : (
            'Unassigned'
          )}
        </>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: '',
    header: 'NAME',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original?.deal_user === ''
            ? original?.contact_user
            : original?.deal_user}
        </>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'franchise',
    header: 'FRANCHISES',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original.deal_data
            ? original.deal_data.franchise &&
              original.deal_data.franchise.name &&
              original.deal_data.franchise.name
              ? original.deal_data.franchise.name
              : ' - '
            : original &&
              original.contact_data &&
              original.contact_data.franchise &&
              original.contact_data.franchise.name
            ? original.contact_data.franchise.name
            : ' - '}
        </>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'agent',
    header: 'Agent',
    cell: ({ row: { original } }) => {
      //
      return (
        <>
          {original.deal_data
            ? original.deal_data.agent &&
              original.deal_data.agent.first_name &&
              original.deal_data.agent.first_name
              ? original.deal_data.agent.first_name
              : ' - '
            : original &&
              original.contact_data &&
              original.contact_data.agent &&
              original.contact_data.agent.first_name
            ? original.contact_data.agent.first_name
            : ' - '}
        </>
      );
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'stage',
    header: 'Type',
    cell: ({ row: { original } }) => {
      const { stage } = original;
      return <>{stage || '-'}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'created_at',
    header: 'CREATED DATE',
    cell: ({ row: { original } }) => {
      const { created_at } = original;
      return <>{moment(created_at).format('DD-MM-YYYY')}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'due_date',
    header: 'DUE DATE',
    cell: ({ row: { original } }) => {
      const { due_date } = original;
      return <>{moment(due_date).format('DD-MM-YYYY')}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'time',
    header: 'Time',
    cell: ({ row: { original } }) => {
      const { time } = original;
      return <>{time || '-'}</>;
    },
    meta: {
      headerProps: { className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  }
];

const TasksTable: React.FC<TasksTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default TasksTable;
