import React from 'react';
import { Spinner } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { useNavigate } from 'react-router-dom';

interface CustomPaginationProps {
  dataPerPage: number;
  totalData: number;
  //   setQueryParamsBase: (params: any) => void; // Replace 'any' with the actual type of queryParams
  currentPage: number;
  isLoading?: boolean;
  //   queryParams: any; // Replace 'any' with the actual type of queryParams
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  dataPerPage,
  totalData,
  //   setQueryParamsBase,
  currentPage,
  isLoading
  //   queryParams
}) => {
  const history = useNavigate();
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
    pageNumbers.push(i);
  }

  let start = 1,
    end = pageNumbers.length;
  if (currentPage - 2 > 1) {
    start = currentPage - 2;
  }
  if (currentPage + 2 < pageNumbers.length) {
    end = currentPage + 2;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {!isLoading && (
        <Pagination style={{ marginTop: '10px' }}>
          <Pagination.First
            onClick={() => history('?page=1')}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => history(`?page=${currentPage - 1}`)}
            disabled={currentPage === 1}
          />
          {start !== 1 && <Pagination.Ellipsis />}
          {pageNumbers.slice(start - 1, end).map(number => (
            <Pagination.Item
              key={number}
              onClick={() => history(`?page=${number}`)}
              active={currentPage === number}
            >
              {number}
            </Pagination.Item>
          ))}
          {end !== pageNumbers.length && <Pagination.Ellipsis />}
          <Pagination.Next
            onClick={() => history(`?page=${currentPage + 1}`)}
            disabled={currentPage === pageNumbers.length}
          />
          <Pagination.Last
            onClick={
              () => history('?&pageSize=10')
              // setQueryParamsBase({ ...queryParams, pageNumber: pageNumbers.length })
            }
            disabled={currentPage === pageNumbers.length}
          />
        </Pagination>
      )}
      {isLoading && (
        <div className="d-flex mb-3" style={{ marginTop: '6px' }}>
          <Spinner animation="border" variant="primary" className="mt-2" />
          <span
            className="ml-2"
            style={{ paddingTop: '16px', marginLeft: '4px' }}
          >
            loading...
          </span>
        </div>
      )}
    </div>
  );
};

export default CustomPagination;
