import React from 'react';
import { OrderAction } from './order-action';
import { OrderId } from './order-Id';

export const OrderHead = () => {
  // const element = <Unicon icon={UilArrowLeft} />;
  return (
    <div className="orderHeader">
      <OrderId />
      <OrderAction />
    </div>
  );
};
