import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getOrdersList, getOrdersStats } from './ordersApi';

interface OrdersState {
  list: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
  stats: {
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    isError: boolean;
  };
}

const initialState: OrdersState = {
  list: {
    isLoading: false,
    data: {
      data: [],
      meta: {}
    },
    isError: false
  },
  stats: {
    isLoading: false,
    data: {},
    isError: false
  }
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOrdersList.pending, state => {
      state.list.isLoading = true;
      state.list.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getOrdersList.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.list.isLoading = false;
        state.list.data = action.payload;
        state.list.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getOrdersList.rejected, state => {
      state.list.isLoading = false;
      state.list.isError = true;
    });
    // order stats
    builder.addCase(getOrdersStats.pending, state => {
      state.stats.isLoading = true;
      state.stats.isError = false; // Reset isError on pending
    });
    builder.addCase(
      getOrdersStats.fulfilled,
      (state, action: PayloadAction<unknown>) => {
        state.stats.isLoading = false;
        state.stats.data = action.payload;
        state.stats.isError = false; // Reset isError on fulfilled
      }
    );
    builder.addCase(getOrdersStats.rejected, state => {
      state.stats.isLoading = false;
      state.stats.isError = true;
    });
  }
});

export default ordersSlice.reducer;
