/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormik } from 'formik';
import ReportCard from '../components/ReportCard';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useEffect } from 'react';
import { getFranchiseList } from '../../../../redux/features/Common/commonApi';

export const Report = () => {
  const dispatch = useAppDispatch();
  const franchiseList = useSelector(
    (state: RootState) => state.common.franchiselist.data
  );
  interface InitialValues {
    from: string;
    to: string;
    type: string;
    franchise_id: string;
    from_pod: string;
    to_pod: string;
    depot_pod: string;
    franchise_id_pod: string;
    service_from: string;
    service_to: string;
    service_type: string;
    service_franchise_id: string;
    podIn_from: string;
    podIn_to: string;
    podIn_franchise_id: string;
    podInStorage_franchise_id: string;
    podInStorage_to: string;
  }
  const initialValues: InitialValues = {
    from: '',
    to: '',
    type: 'all',
    franchise_id: 'all',
    from_pod: '',
    to_pod: '',
    depot_pod: '',
    franchise_id_pod: '',
    service_from: '',
    service_to: '',
    service_type: 'all',
    service_franchise_id: 'all',
    podIn_from: '',
    podIn_to: '',
    podIn_franchise_id: 'all',
    podInStorage_franchise_id: 'all',
    podInStorage_to: ''
  };

  const generateUrl = (check: any) => {
    const link =
      check === 'from'
        ? `https://api.easystorage.com/api/export-orders?franchise_id=${formik?.values?.franchise_id}&type=${formik?.values?.type}&to=${formik?.values?.to}&from=${formik?.values?.from}&export=booked`
        : check === 'service_from'
        ? `https://api.easystorage.com/api/export-orders?franchise_id=${formik?.values?.service_franchise_id}&type=${formik?.values?.type}&to=${formik?.values?.service_to}&from=${formik?.values?.service_from}&export=service`
        : check === 'from_pod'
        ? `https://api.easystorage.com/api/pod-bal-export?franchise_id=${formik?.values?.franchise_id_pod}&depot=all&to=${formik?.values?.to_pod}&from=${formik?.values?.from_pod}&Export=Export`
        : check === 'podIn_from'
        ? `https://api.easystorage.com/api/export-orders?franchise_id=${formik?.values?.podIn_franchise_id}&to=${formik?.values?.podIn_to}&export=pod-in-storage`
        : '';
    return link;
  };

  const handleSubmit = (check: any) => {
    const downloadLink = generateUrl(check);
    const anchor = document.createElement('a');

    // Set the href and download attributes
    anchor.href = downloadLink;
    // anchor.download = 'downloaded-file'; // You can set the desired file name

    // Append the anchor to the document
    document.body.appendChild(anchor);

    // Trigger a click event on the anchor
    anchor.click();

    // Remove the anchor from the document
    document.body.removeChild(anchor);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    // if (!franchiseList.data) {
    dispatch(getFranchiseList());
    // }
  }, [dispatch]);

  return (
    <div>
      <ReportCard
        title={'Export Order Per Booking Date'}
        formik={formik}
        franchiseList={franchiseList}
        from={'from'}
        to={'to'}
        franchise_id={'franchise_id'}
        handleSubmit={handleSubmit}
      />
      <ReportCard
        title={'Export Order Per Service Date'}
        formik={formik}
        franchiseList={franchiseList}
        from={'service_from'}
        to={'service_to'}
        franchise_id={'service_franchise_id'}
        handleSubmit={handleSubmit}
      />
      <ReportCard
        title={'Export Opening/Closing balance'}
        formik={formik}
        franchiseList={franchiseList}
        from={'from_pod'}
        to={'to_pod'}
        franchise_id={'franchise_id_pod'}
        handleSubmit={handleSubmit}
      />
      <ReportCard
        title={'Export Pod In Storage'}
        formik={formik}
        franchiseList={franchiseList}
        from={'podIn_from'}
        to={'podIn_to'}
        franchise_id={'podIn_franchise_id'}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};
