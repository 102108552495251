/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { getFeedsList, getTimelineList } from './timelineApi';

interface TimelineState {
  isLoading: boolean;
  profileList: any;
  feedsList: any;
  permission: any;
  status: string;
  error: boolean;
}

const initialState: TimelineState = {
  isLoading: false,
  profileList: [],
  feedsList: [],
  permission: [],
  status: 'idle',
  error: false
};

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //get api lists
    builder.addCase(getTimelineList.pending, state => {
      state.isLoading = true;
      state.error = false; // Reset isError on pending
    });
    builder.addCase(getTimelineList.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.profileList = action.payload.data1;
      state.feedsList = action.payload.data2;
      //set permissions
      if (action.payload.data3) {
        const permission = action.payload.data3.data.permissions;
        const obj: any = {};
        for (const item of permission) {
          const split = item.split('_');
          let key: any = '';
          for (let i = 0; i < split.length - 1; i++) {
            key += `${split[i]}_`;
          }

          obj[key.substring(0, key?.length - 1)] = {
            ...obj[key.substring(0, key?.length - 1)],
            [split[split.length - 1]]: true
          };
        }
        state.permission = obj;
      }

      state.error = false; // Reset isError on fulfilled
    });
    builder.addCase(getTimelineList.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
    //get feeds lists
    builder.addCase(getFeedsList.pending, state => {
      state.isLoading = true;
      state.error = false; // Reset isError on pending
    });
    builder.addCase(getFeedsList.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.feedsList = action.payload.data;
      state.error = false; // Reset isError on fulfilled
    });
    builder.addCase(getFeedsList.rejected, state => {
      state.isLoading = false;
      state.error = true;
    });
  }
});

export default timelineSlice.reducer;
