/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequest } from 'helpers/apiCall';

export const getAgentsList = createAsyncThunk('agents/list', async () => {
  try {
    const response = await makeRequest('GET', `contact/agent-list`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const assignAvailability = async (data: any) => {
  const response = await makeRequest(
    'POST',
    'user/assign/agent-availability',
    data
  );
  return response;
};
