/* eslint-disable react/prop-types */
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CustomPagination from 'components/base/CustomPagination';
import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';

interface Depots {
  id: string;
  name: string;
  short_name: string;
  manager_name: string;
  phone: string;
  manager_email: string;
  status: number;
}

interface DepotsTableProps {
  paginationProps: {
    page: number;
    dataPerPage: number;
    total: number;
    current_page: number;
    isLoading: boolean;
  }; // Replace 'YourPaginationPropsType' with the actual type
}

export const depotsTableColumns: ColumnDef<Depots>[] = [
  {
    accessorKey: 'id',
    header: 'DEPOT ID',
    cell: ({ row: { original } }) => {
      const { id } = original;
      return <>{id}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'name',
    header: 'DEPOT NAME',
    cell: ({ row: { original } }) => {
      const { name } = original;
      return <>{name}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'short_name',
    header: 'DEPOT SHORT',
    cell: ({ row: { original } }) => {
      const { short_name } = original;
      return <>{short_name}</>;
    },
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'manager_name',
    header: 'MANAGER NAME',
    cell: ({ row: { original } }) => {
      const { manager_name } = original;
      return <>{manager_name}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'phone',
    header: 'PHONE',
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return <>{phone}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'manager_email',
    header: 'EMAIL',
    cell: ({ row: { original } }) => {
      const { manager_email } = original;
      return <>{manager_email}</>;
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    id: 'status',
    // accessorFn: ({ payment_status }) => payment_status.label,
    header: 'DEPOT STATUS',
    cell: ({ row: { original } }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status } = original;
      if (status == 1) {
        return (
          <Badge
            bg={'success'}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                icon={'feather-check'}
                size={12.8}
                className="ms-1"
              />
            }
          >
            Active
          </Badge>
        );
      } else {
        return (
          <Badge
            bg={'danger'}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
            icon={
              <FeatherIcon
                icon={'feather-check'}
                size={12.8}
                className="ms-1"
              />
            }
          >
            InActive
          </Badge>
        );
      }
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3' }
    }
  }
];

const DepotsTable: React.FC<DepotsTableProps> = ({ paginationProps }) => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <CustomPagination
        dataPerPage={paginationProps?.dataPerPage}
        totalData={paginationProps.total}
        currentPage={paginationProps.current_page}
        isLoading={paginationProps.isLoading}
      />
      {/* <AdvanceTableFooter pagination /> */}
    </div>
  );
};

export default DepotsTable;
